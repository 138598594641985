import {
  Alert,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Tabs,
  Tag,
  Timeline,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MultiStepComponent from "../../../../components/MultiStepComponent";
import {
  STANDARD_MOMENT_FORMAT,
  eeoForm,
  personalDataCollection,
} from "../../../../data/constants";
import {
  getPartner,
  selectLoading,
  selectUser,
} from "../../../../redux/auth/selectors";
import ATSService from "../../../../service/ATSService";
import CrudService from "../../../../service/CrudService";
import PublicService from "../../../../service/PublicService";
import UploadService from "../../../../service/UploadService";
import CVTemplate from "../../../CandidateCV/CVTemplate";
import { Button } from "../../../Landing/Button";

const { TabPane } = Tabs;

function convertLinksToHTML(text) {
  var urlWithTitleRegex = /\{([^}]+)\}\[([^\]]+)\]/g;

  var replacedText = text.replace(
    urlWithTitleRegex,
    function (match, url, title) {
      // Use the title as the link text
      return '<a href="' + url + '" target="_blank">' + title + "</a>";
    }
  );

  var urlRegex =
    /(?<!href="|href='|">)(https?:\/\/[^\s\n<]+)(?=[,.!?;]*($|\s|<))/g;

  replacedText = replacedText.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });

  return replacedText;
}

const getDocumentStatus = (document) => {
  if (!document?.candidateSignature) return "Pending";
  if (
    !document?.body?.includes?.(
      "@[Hiring Authority Signature](hiringAuthoritySignature)"
    )
  )
    return "Completed";
  if (document?.hiringManagerSignature) return "Completed";
  return "Candidate Signed";
};
const getDocumentColor = (document) => {
  if (!document?.candidateSignature) return "orange";
  if (
    !document?.body?.includes?.(
      "@[Hiring Authority Signature](hiringAuthoritySignature)"
    )
  )
    return "green";
  if (document?.hiringManagerSignature) return "green";
  return "blue";
};

function downloadAndShowPDF(pdfUrl, setLocalPdfUrl) {
  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const localUrl = URL.createObjectURL(blob);
      setLocalPdfUrl(localUrl); // Assuming you have a state setter for the local PDF URL
    })
    .catch((error) => console.error("Error downloading the PDF:", error));
}

const LOG_LOAD_PAGINATION = 25;

const DetailsModal = ({ candidateId }) => {
  const partner = useSelector(getPartner);
  const user = useSelector(selectUser);

  const [AILoading, setAILoading] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [vacancyData, setVacancyData] = useState(null);
  const [candidateTimeline, setCandidateTimeline] = useState([]);
  const [candidateTimelineTotal, setCandidateTimelineTotal] = useState(0);
  const [currentLogsPage, setCurrentLogsPage] = useState(1);
  const [CV, setCV] = useState(null);
  const [interviewScripts, setInterviewScripts] = useState([]);
  const [candidateDocuments, setCandidateDocuments] = useState([]);
  const [surveySubmission, setSurveySubmission] = useState(null);
  const [alternativePDFLocalURL, setAlternativePDFLocalURL] = useState(null);
  const [AIInstructions, setAIInstructions] = useState("");
  const [activeTab, setActiveTab] = useState("ApplicationForm");
  const socket = useRef(null);
  const socketPing = useRef(null);
  const loading = useSelector(selectLoading);

  const fileInput = useRef(null);

  useEffect(() => {
    if (!candidateId) return;

    fileInput.current = document.getElementById("fileInput34");
    if (fileInput.current)
      fileInput.current.addEventListener("change", async () => {
        const selectedFile = fileInput.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 5);

          await ATSService.submitCV(candidateId, result.data.secure_url);

          CrudService.search("CV", 1, 1, {
            filters: { candidate: candidateId, submitted: true },
          }).then(({ data }) => {
            if (data.items?.[0]) setCV(data.items?.[0]);
            else setCV(false);
          });
          fileInput.current.files[0] = "";
        } else {
          console.log("No file selected.");
        }
      });
  }, [candidateId]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (CV?.alternativeUrl)
      downloadAndShowPDF(alternativePDFLocalURL, setAlternativePDFLocalURL);
  }, [CV]);

  useEffect(() => {
    if (!candidateId) return;

    CrudService.search("VacancySubmission", 1, 1, {
      filters: { _id: candidateId },
      populate: {
        path: "emailSent.user_id",
        select: "firstName lastName email",
      },
      populate2: {
        path: "smsSent.user_id",
        select: "firstName lastName email",
      },
    }).then(({ data }) => {
      if (data.items?.[0]) {
        setCandidate(data.items[0]);
        CrudService.search("Vacancy", 1, 1, {
          filters: { _id: data.items?.[0].VacancyId },
        }).then(({ data }) => {
          if (data.items?.[0]) setVacancyData(data.items?.[0]);
        });
      } else setCandidate(false);
    });

    CrudService.search("CV", 1, 1, {
      filters: { candidate: candidateId, submitted: true },
    }).then(({ data }) => {
      if (data.items?.[0]) setCV(data.items?.[0]);
      else setCV(false);
    });

    CrudService.search("SurveySubmission", 1, 1, {
      filters: { VacancySubmissionId: candidateId },
    }).then(({ data }) => {
      if (data.items?.[0]) setSurveySubmission(data.items?.[0]);
      else setSurveySubmission(false);
    });

    CrudService.search("InterviewScript", 1000, 1, {
      filters: { candidate: candidateId },
    }).then(({ data }) => {
      setInterviewScripts(data.items);
    });

    CrudService.search("CandidateLogs", LOG_LOAD_PAGINATION, 1, {
      filters: { candidate: candidateId },
      sort: { createdAt: -1 },
      populate: "user_id",
    }).then(({ data }) => {
      setCandidateTimeline(data.items);
      setCandidateTimelineTotal(data.total);
    });

    ATSService.getCandidateDocuments(candidateId).then(({ data }) =>
      setCandidateDocuments(data)
    );
  }, [candidateId]);

  const onLogsLoadMore = () => {
    CrudService.search(
      "CandidateLogs",
      LOG_LOAD_PAGINATION,
      currentLogsPage + 1,
      {
        filters: { candidate: candidateId },
        sort: { createdAt: -1 },
        populate: "user_id",
      }
    ).then(({ data }) => {
      setCandidateTimeline((a) => [...a, ...data.items]);
      setCandidateTimelineTotal(data.total);
    });
    setCurrentLogsPage((a) => a + 1);
  };

  const requestInterview = () => {
    if (AILoading) return;
    if (candidate === null) return;
    if (CV === null) return;
    if (surveySubmission === null) return;

    socket.current = new WebSocket(
      `wss://booklified-chat-socket.herokuapp.com`
    );

    socket.current.addEventListener("open", async () => {
      socketPing.current = setInterval(
        () => socket.current.send(JSON.stringify({ id: "PING" })),
        30000
      );

      const content = `Hello, I am in the process of interviewing a candidate and require your expertise in recruitment to enhance the interview structure and content. Please provide me with the following:

A breakdown of the interview stages, including:
- The name of each step.
- A concise description for each step.
- The recommended duration for each step.
- A curated list of high-quality interview questions that are:
-- Tailored specifically to the job position in question.
-- Based on the candidate's application details.
-- Reflective of the candidate's CV and experiences.
-- Explanation of the intent behind each question and the anticipated response from a suitable candidate.

Please ensure that the questions are designed to elicit responses that demonstrate the candidate's suitability for the role, their technical competencies, problem-solving abilities, and cultural fit. The goal is to create an interview experience that is both thorough and insightful, allowing for an accurate assessment of the candidate's potential for the position.

Here is the candidate data:
${JSON.stringify(candidate)}

Here is the CV data:
${
  CV?.alternativeUrl
    ? (await PublicService.getPDFText(CV.alternativeUrl))?.data?.text?.slice?.(
        0,
        600
      )
    : JSON.stringify(CV)
}


${AIInstructions}

It is imperative that your reply contains nothing beyond the required interview script. Don't start your response by answering to me. Instead get right into the script. Do not add anything else into your answer.
`;

      setAILoading(true);
      socket.current.send(
        JSON.stringify({
          id: "OPEN_AI_PROMPT",
          payload: {
            content,
            model: "gpt-3.5-turbo-16k",
            partner: partner._id,
          },
        })
      );
    });

    socket.current.addEventListener("message", async (event) => {
      const message = JSON.parse(event.data);
      const response = message.payload?.response;

      await CrudService.create("InterviewScript", {
        message: response,
        candidate: candidateId,
      });
      await CrudService.search("InterviewScript", 1000, 1, {
        filters: { candidate: candidateId },
      }).then(({ data }) => {
        setInterviewScripts(data.items);
      });
      setAILoading(false);
      if (socketPing.current) clearInterval(socketPing.current);
    });
  };

  if (candidate === null) return <Skeleton active />;
  if (CV === null) return <Skeleton active />;
  if (surveySubmission === null) return <Skeleton active />;
  return (
    <>
      <h2 className="text-lg font-bold mb-1">Candidate Folder</h2>
      <div className="flex justify-between gap-5 mt-2 mb-5 items-center">
        <h3 className="text-md font-semibold mb-2 whitespace-nowrap">
          {candidate?.formData?.firstname} {candidate?.formData?.lastname}
        </h3>

        <Select
          mode="tags"
          className="w-full mb-1"
          size="small"
          placeholder="Tags"
          value={candidate?.tags}
          onChange={async (e) => {
            setCandidate((x) => ({
              ...x,
              tags: e,
            }));
            await CrudService.update("VacancySubmission", candidate._id, {
              tags: e,
              searchIndex: `${candidate?.searchIndex ?? ""} ${e}`,
            });
          }}
        />
      </div>

      {candidate?.rejected && (
        <Alert
          type="error"
          message={`This candidate has been marked for rejection. Provided reason: ${
            candidate?.rejectionReason ?? ""
          }`}
        />
      )}

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Application Form" key="ApplicationForm">
          {candidate?.formData && candidate?.form ? (
            <MultiStepComponent
              displaySteps={true}
              steps={[
                ...(Array.isArray(candidate.form)
                  ? candidate.form
                  : typeof candidate.form === "object"
                  ? Object.values(candidate.form)
                  : []),
                vacancyData?.eeodc
                  ? {
                      id: "eeodc",
                      name: "EEODC",
                      form: [
                        {
                          type: "custom",
                          CustomInputComponent: () => (
                            <>
                              <h2 className="font-bold text-lg">
                                EEO Data Collection
                              </h2>
                              <Alert
                                type="info"
                                message="The following questions are part of our commitment to ensuring equal opportunities. Participation in this data collection is entirely voluntary, and all questions are optional. Your responses will not influence any individual hiring decisions. Instead, the data collected will be aggregated across multiple participants. This process is designed exclusively for our internal use, to monitor our progress towards achieving specific goals related to ensuring equal employment opportunities. Should you prefer not to respond to any query, please feel free to proceed to the next section of the form."
                              />
                            </>
                          ),
                        },
                        ...eeoForm,
                      ],
                    }
                  : null,
                {
                  id: "contact",
                  name: "Contact Information",
                  form: [...personalDataCollection],
                },
              ].filter((a) => !!a)}
              defaultFormData={candidate?.formData ? candidate?.formData : []}
              readOnly
            />
          ) : (
            <Alert
              type="info"
              message="Candidate has not submitted the application form"
            />
          )}
        </TabPane>
        <TabPane tab="Edit Contact" key="EditContact">
          {candidate?.formData && (
            <MultiStepComponent
              steps={[
                {
                  id: "contact",
                  name: "Contact Information",
                  form: [...personalDataCollection],
                },
              ]}
              defaultFormData={
                candidate?.formData
                  ? {
                      ...candidate?.formData,
                      phone: `${candidate?.formData?.phone}` ?? "",
                    }
                  : []
              }
              loading={loading}
              displaySteps={false}
              finishText="Save"
              wrapperClassName="px-5"
              onFinish={async (formData) => {
                await ATSService.updateCandidateDetails(candidateId, formData);
                await CrudService.search(
                  "CandidateLogs",
                  LOG_LOAD_PAGINATION,
                  1,
                  {
                    filters: { candidate: candidateId },
                    sort: { createdAt: -1 },
                    populate: "user_id",
                  }
                ).then(({ data }) => {
                  setCandidateTimeline(data.items);
                  setCandidateTimelineTotal(data.total);
                  setCurrentLogsPage(1);
                });
                await CrudService.search("VacancySubmission", 1, 1, {
                  filters: { _id: candidateId },
                }).then(({ data }) => {
                  if (data.items?.[0]) setCandidate(data.items?.[0]);
                  else setCandidate(false);
                });
              }}
            />
          )}

          {user?.email && "info@booklified.com" && (
            <div>{JSON.stringify(candidate.formData)}</div>
          )}
        </TabPane>

        <TabPane tab="CV" key="CV">
          {CV?.alternativeUrl ? (
            <div>
              <div className="flex justify-end mb-2">
                <FaExternalLinkAlt
                  onClick={() => {
                    window.open(CV?.alternativeUrl);
                  }}
                  size={20}
                  className="cursor-pointer"
                />
              </div>
              {CV?.alternativeUrl && (
                <iframe src={CV?.alternativeUrl} width="100%" height="600px" />
              )}
            </div>
          ) : CV ? (
            <CVTemplate CVData={{ cv: CV, candidate }} />
          ) : (
            <>
              <Alert type="info" message="Candidate has not submitted the CV" />

              <button
                className="py-2 px-3 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
                type="button"
                onClick={() => {
                  if (loading) return;
                  fileInput?.current?.click?.();
                }}
              >
                <div
                  className="w-full border-box "
                  style={{
                    background: "center center / cover no-repeat transparent",
                  }}
                >
                  <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                    <div className="transition-wrapper" style={{}}>
                      <div className="wrapper break-words text-center p-4 bg-indigo-500 rounded text-white font-bold">
                        {loading ? (
                          <Spin>
                            <h3>Upload manually</h3>
                          </Spin>
                        ) : (
                          <h3>Upload manually</h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </>
          )}
        </TabPane>
        <TabPane tab="Interview" key="Interview">
          <div>
            {!localStorage?.informationAIInterviewPrep && (
              <Alert
                type="info"
                message="Enhance your interview process with our advanced interview companion. This tool is designed to generate insightful questions for you to pose to your candidates, ensuring a comprehensive evaluation aligned with the specific requirements of the position. Please provide any pertinent details in the textbox below to tailor the companion to your unique interviewing needs."
                closable
                onClose={() =>
                  (localStorage.informationAIInterviewPrep = "true")
                }
              />
            )}
            <textarea
              rows={4}
              type="text"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={AIInstructions}
              onChange={(e) => setAIInstructions(e.target.value)}
            />
            <div className="mt-2">
              <Button
                type="submit"
                variant="solid"
                color="blue"
                className="w-full"
                onClick={requestInterview}
                loading={AILoading}
              >
                <span>Generate Script</span>
              </Button>
            </div>

            {interviewScripts
              ?.sort?.((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.map?.((interviewScript) => (
                <div
                  className="border-b-2 pb-2 border-gray-100 my-5 relative pt-8"
                  key={interviewScript?._id}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: interviewScript?.message?.replace?.(
                        /\n/g,
                        "<br>"
                      ),
                    }}
                  />
                  <div className="absolute top-1 start-1 text-sm text-gray-400">
                    Interview companion at{" "}
                    {moment(interviewScript.createdAt).format(
                      "HH:mm, Do MMM YYYY"
                    )}
                  </div>
                  <div className="absolute top-1 end-1">
                    <Space>
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={async () => {
                          if (!interviewScript?._id) return;
                          await CrudService.delete(
                            "InterviewScript",
                            interviewScript?._id
                          ).then(({ data }) => {
                            setInterviewScripts(data.items);
                          });
                          await CrudService.search("InterviewScript", 1000, 1, {
                            filters: { candidate: candidateId },
                          }).then(({ data }) => {
                            setInterviewScripts(data.items);
                          });
                        }}
                      >
                        <MdDelete
                          size={18}
                          className="cursor-pointer text-red-500"
                        />
                      </Popconfirm>
                    </Space>
                  </div>
                </div>
              ))}
          </div>
        </TabPane>
        <TabPane tab="Survey Form" key="SurveyForm">
          {surveySubmission?.formData && surveySubmission?.form ? (
            <MultiStepComponent
              displaySteps={true}
              steps={[
                ...(Array.isArray(surveySubmission?.form)
                  ? surveySubmission?.form
                  : typeof surveySubmission?.form === "object"
                  ? Object.values(surveySubmission?.form)
                  : []),
              ]}
              defaultFormData={
                surveySubmission?.formData ? surveySubmission?.formData : []
              }
              readOnly
            />
          ) : (
            <Alert
              type="info"
              message="Candidate has not submitted the survey"
            />
          )}
        </TabPane>
        <TabPane tab="Email History" key="emailSent">
          {candidate?.emailSent?.length > 0 ? (
            <>
              <div className="flex flex-col space-y-4">
                {candidate.emailSent
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((sms) => (
                    <div key={sms._id}>
                      <div
                        className="bg-indigo-500 font-bold text-white py-2 px-4 rounded-t-lg mt-2"
                        dangerouslySetInnerHTML={{
                          __html: sms.subject?.replace?.(/\n/g, "<br>"),
                        }}
                      ></div>
                      <div
                        className="bg-indigo-500 text-white py-2 px-4 rounded-b-lg "
                        dangerouslySetInnerHTML={{
                          __html: convertLinksToHTML(sms.body)?.replace?.(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      ></div>
                      <div className="text-right text-xs text-gray-400">
                        <div className="text-right text-xs text-gray-400">
                          {sms?.user_id?.email ? (
                            <>
                              {sms?.user_id?.firstName ?? ""}{" "}
                              {sms?.user_id?.lastName ?? ""} (
                              {sms?.user_id?.email ?? ""})
                            </>
                          ) : (
                            "System"
                          )}
                        </div>
                      </div>
                      <div className="text-right text-xs text-gray-400 mt-1">
                        {moment(sms.timestamp).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <Alert
              type="info"
              message="Candidate did not receive any emails from you"
            />
          )}
        </TabPane>
        <TabPane tab="SMS History" key="smsSent">
          {candidate?.smsSent?.length > 0 ? (
            <>
              <div className="flex flex-col space-y-4">
                {candidate.smsSent
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((sms) => (
                    <div key={sms._id}>
                      <div
                        className="bg-indigo-500 text-white py-2 px-4 rounded-lg mt-2"
                        dangerouslySetInnerHTML={{
                          __html: sms.text?.replace?.(/\n/g, "<br>"),
                        }}
                      ></div>
                      <div className="text-right text-xs text-gray-400">
                        {sms?.user_id?.email ? (
                          <>
                            {sms?.user_id?.firstName ?? ""}{" "}
                            {sms?.user_id?.lastName ?? ""} (
                            {sms?.user_id?.email ?? ""})
                          </>
                        ) : (
                          "System"
                        )}
                      </div>
                      <div className="text-right text-xs text-gray-400 mt-1">
                        {moment(sms.timestamp).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <Alert
              type="info"
              message="Candidate did not receive any SMS messages from you"
            />
          )}
        </TabPane>

        <TabPane tab="Tracking" key="Tracking">
          <div className="p-4">
            <table className="w-full border-collapse">
              <tbody>
                {Object.entries(candidate?.tracking ?? {})
                  .filter((e) => e?.[0] !== "_id")
                  .map(([key, value]) => (
                    <tr
                      key={key}
                      className="border-t border-gray-200 dark:border-gray-600 "
                    >
                      <td className="py-2 pr-4 font-semibold">{key}:</td>
                      <td className="py-2">{value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </TabPane>

        <TabPane tab="Documents" key="Documents">
          <div className="p-4 overflow-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-t border-gray-200 dark:border-gray-600 ">
                  <td className="py-2 pr-4 font-semibold">Title</td>
                  <td className="py-2 pr-4 font-semibold">Status</td>
                  <td className="py-2 pr-4 font-semibold">Received Date</td>
                  <td className="py-2 pr-4 font-semibold">Signed Date</td>
                  <td className="py-2 pr-4 font-semibold">Pages</td>
                </tr>
              </thead>
              <tbody>
                {candidateDocuments
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((document) => (
                    <tr
                      key={document._id}
                      className="border-t border-gray-200 dark:border-gray-600 "
                    >
                      <td
                        className="py-2 pr-4 whitespace-nowrap max-w-[175px] overflow-hidden truncate"
                        title={document.title}
                      >
                        {document.title}
                      </td>
                      <td className="py-2 pr-4 whitespace-nowrap">
                        <Tag color={getDocumentColor(document)}>
                          {getDocumentStatus(document)}
                        </Tag>
                      </td>
                      <td className="py-2 pr-4 ">
                        {moment(document.createdAt).format(
                          STANDARD_MOMENT_FORMAT
                        )}
                      </td>
                      <td className="py-2 pr-4 ">
                        {moment(document.candidateSignatureDate).format(
                          STANDARD_MOMENT_FORMAT
                        )}
                      </td>
                      <td className="py-2 pr-4">
                        <Space>
                          <Link
                            className="py-1 px-2 text-indigo-500 border border-indigo-500 whitespace-nowrap rounded-md mx-auto max-w-md block cursor-pointer w-full text-center "
                            to={`/offer?token=${
                              document?.candidateToken ?? ""
                            }`}
                            target="_blank"
                          >
                            Candidate
                          </Link>
                          <Link
                            className="py-1 px-2 text-indigo-500 border border-indigo-500 whitespace-nowrap rounded-md mx-auto max-w-md block cursor-pointer w-full text-center "
                            to={`/hiringManagerSign?token=${
                              document?.hiringManagerToken ?? ""
                            }`}
                            target="_blank"
                          >
                            Hiring Manager
                          </Link>
                        </Space>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </TabPane>

        <TabPane tab="Logs" key="Logs">
          {candidateTimeline.length > 0 ? (
            <div>
              <div>
                <Timeline
                  items={candidateTimeline.map((t) => ({
                    color: t.color,
                    children: (
                      <>
                        <div>{t.message ?? ""}</div>
                        <div className="text-sm">
                          Performed by: {t.user_id?.firstName ?? ""}{" "}
                          {t.user_id?.lastName ?? ""} ({t.user_id?.email ?? ""})
                        </div>
                        <div className="text-xs">
                          {moment(t.createdAt).format(STANDARD_MOMENT_FORMAT)}
                        </div>
                      </>
                    ),
                  }))}
                />
              </div>

              {candidateTimelineTotal >=
                LOG_LOAD_PAGINATION * currentLogsPage && (
                <div className="flex justify-center mt-5">
                  <Button loading={loading} onClick={onLogsLoadMore}>
                    Load more
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <Alert type="info" message="Nothing to show" />
          )}
        </TabPane>
      </Tabs>
    </>
  );
};

export default DetailsModal;
