import { Skeleton, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiStepComponent from "../../../components/MultiStepComponent";
import CrudService from "../../../service/CrudService";

const VacancyPrepublish = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyData(null);

    CrudService.getSingle("Vacancy", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [searchParams]);

  const steps = [
    {
      id: "step1",
      name: "General Information",
      form: [
        {
          fieldName: "name",
          label: "Vacancy Name",
          type: "input",
          placeholder: "Enter vacancy name",
        },
        {
          fieldName: "description",
          label: "Vacancy Description",
          type: "textarea",
          placeholder: "Enter vacancy description",
          rows: 6,
        },
      ],
    },
    {
      id: "step2",
      name: "Key Attributes",
      form: [
        {
          fieldName: "keyBenefits",
          label: "Key Benefits",
          type: "textarea",
          placeholder: "Enter key benefits",
          rows: 6,
        },
        {
          fieldName: "requiredSkills",
          label: "Required Skills",
          type: "textarea",
          placeholder: "Enter required skills",
          rows: 6,
        },
        {
          fieldName: "location",
          label: "Location",
          type: "input",
          placeholder: "Remote",
        },
        {
          fieldName: "companyName",
          label: "Company Name",
          type: "input",
        },
        {
          fieldName: "companyWebsite",
          label: "Company Website",
          type: "input",
        },
        {
          fieldName: "companyAddress",
          label: "Company Address",
          type: "textarea",
        },
      ],
    },
    {
      id: "step3",
      name: "Contract",
      form: [
        {
          fieldName: "engagementType",
          label: "Engagement Type",
          type: "select",
          options: [
            { value: "Permanent", label: "Permanent" },
            { value: "Temporary", label: "Temporary" },
            { value: "Fixed-Term", label: "Fixed-Term" },
            { value: "Freelance", label: "Freelance" },
            { value: "Project-based", label: "Project-based" },
            { value: "Retainer", label: "Retainer" },
            { value: "Seasonal", label: "Seasonal" },
            { value: "Internship", label: "Internship" },
            { value: "Apprenticeship", label: "Apprenticeship" },
            { value: "Commission-Based", label: "Commission-Based" },
            { value: "Contract-to-Hire", label: "Contract-to-Hire" },
            { value: "Partnership", label: "Partnership" },
          ],
        },
        {
          fieldName: "contractType",
          label: "Contract Type",
          type: "select",
          options: [
            { value: "Full-time", label: "Full-time" },
            { value: "Part-time", label: "Part-time" },
            { value: "Casual", label: "Casual" },
            { value: "Zero-Hours", label: "Zero-Hours" },
            { value: "Profit-share", label: "Profit-share" },
          ],
        },
      ],
    },
  ];

  if (!vacancyData) return <Skeleton active />;
  return (
    <>
      <div>
        <MultiStepComponent
          displaySteps={true}
          displayUndoRedo
          AIEnhancements={true}
          steps={steps}
          defaultFormData={{
            ...vacancyData,
          }}
          onNext={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            delete formData._id;
            await CrudService.update("Vacancy", id, {
              ...formData,
            });
          }}
          onFinish={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            delete formData._id;
            await CrudService.update("Vacancy", id, {
              ...formData,
            });

            navigate(`/dashboard/vacancyedit?id=${id}`);
          }}
        />
      </div>
    </>
  );
};

export default VacancyPrepublish;
