import { Alert, Button } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { STANDARD_MOMENT_FORMAT } from "../../data/constants";
import { getPartner, selectUser } from "../../redux/auth/selectors";
import CalendlyService from "../../service/CalendlyService";
import SMTPModalComponent from "./SMTPModal";

const Warnings = () => {
  const user = useSelector(selectUser);
  const partner = useSelector(getPartner);
  const [calendlyToken, setCalendlyToken] = useState(null);
  const [calendlyOrganization, setCalendlyOrganization] = useState(null);
  const [SMTPModal, setSMTPModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [smtp, setSmtp] = useState(null);

  useEffect(() => {
    if (partner?.calendlyclientId)
      CalendlyService.getCurrentToken()
        .then(({ data }) => {
          setCalendlyToken(data);
          setLoading(false);
        })
        .catch(() => setCalendlyToken(null));
  }, []);

  useEffect(() => {
    if (partner?.calendlyclientId)
      CalendlyService.getOrganization()
        .then(({ data }) => {
          setCalendlyOrganization(data);
        })
        .catch(() => setCalendlyOrganization(null));
  }, []);

  useEffect(() => {
    setSmtp(user?.smtp);
  }, [user]);

  const trialDate = useMemo(() => {
    if (!partner) return null;
    if (!user) return null;

    const currentDate = new Date();
    const trialStartDate = new Date(user.createdAt);
    trialStartDate.setDate(trialStartDate.getDate() + partner.trialDays);
    const isTrialGranted =
      user.partnerGrantedTrialEnd &&
      currentDate <= new Date(user.partnerGrantedTrialEnd);
    const isTrialGrantedGone =
      user.partnerGrantedTrialEnd &&
      currentDate > new Date(user.partnerGrantedTrialEnd);

    const userTier =
      !user.subscription?.paid || !user.subscription?.tier
        ? "free"
        : user.subscription.tier;

    const isTrial =
      userTier === "free" &&
      !isTrialGrantedGone &&
      (isTrialGranted || currentDate <= trialStartDate);

    if (!isTrial) return null;

    if (isTrialGranted) return user.partnerGrantedTrialEnd;
    if (trialStartDate) return trialStartDate;
    return null;
  }, [user, partner]);

  return (
    <>
      {!localStorage.closedTrialInfo &&
        trialDate &&
        partner?.owner !== user?._id && (
          <Alert
            type="info"
            className="my-1"
            message={
              <>
                Your current trial period of our software is active. Be aware
                that this trial version will expire on{" "}
                {moment(trialDate).format(STANDARD_MOMENT_FORMAT)}. For
                continued access and features, kindly consider{" "}
                <Link to="/dashboard/billing" className="underline">
                  upgrading to a full version
                </Link>
                .
              </>
            }
            banner
            closable
            onClose={() => {
              localStorage.closedTrialInfo = "true";
            }}
          />
        )}

      {!loading &&
        !calendlyToken?.access_token &&
        !localStorage.closedCalendlyConnectInfo && (
          <Alert
            type="warning"
            className="my-1"
            message={
              <>
                <h1>
                  <strong>
                    Important: Connect Your Calendly for an Enhanced Interview
                    Scheduling Experience
                  </strong>
                </h1>

                <div>
                  To streamline your interview scheduling process, we highly
                  recommend connecting your Calendly account. This integration
                  will offer a more efficient and organized scheduling
                  experience. Please follow the link below to set up your
                  Calendly connection:
                </div>

                <div>
                  <Button
                    type="primary"
                    className="my-1"
                    onClick={async () => {
                      const res = await CalendlyService.getAuthURI();
                      window.location.href = res.data.authUri;
                    }}
                  >
                    Connect Calendly
                  </Button>
                </div>

                <div>
                  (Note: Clicking the link will redirect you to the Calendly
                  authorization page.)
                </div>
              </>
            }
            banner
            closable
            onClose={() => {
              localStorage.closedCalendlyConnectInfo = "true";
            }}
          />
        )}

      {!!user &&
        !!user?.smtp &&
        !user?.smtp?.connectionStatus &&
        !localStorage.closedSMTPConnectInfo && (
          <Alert
            type="warning"
            className="my-1"
            message={
              <>
                <h1>
                  <strong>
                    Enhance Your Communication: Connect Your SMTP Service
                  </strong>
                </h1>

                <div>
                  By integrating your SMTP service with our platform, you unlock
                  the ability to send automated emails directly from your inbox.
                  This seamless connection ensures a more personalized
                  communication experience with your candidates and allows for
                  effortless tracking of all email interactions.
                </div>

                <div>
                  <a
                    onClick={async () => {
                      setSMTPModal(true);
                    }}
                  >
                    Connect SMTP
                  </a>
                </div>
              </>
            }
            banner
            closable
            onClose={() => {
              localStorage.closedSMTPConnectInfo = "true";
            }}
          />
        )}

      {calendlyOrganization?.plan === "basic" &&
        calendlyOrganization?.stage === "free" &&
        !localStorage.closedCalendlySubscriptionInfo && (
          <Alert
            type="warning"
            className="my-1"
            message={
              <>
                <div>
                  To ensure you experience the full benefits of our system, we
                  recommend upgrading to the Standard package in your Calendly
                  account. Our platform is optimized to work seamlessly with the
                  Standard package, providing you with the most efficient and
                  integrated experience.
                </div>

                <div>
                  Without this upgrade, you may encounter limitations in certain
                  features, such as the automated candidate progression in your
                  Applicant Tracking System (ATS) when appointments are
                  scheduled. The Standard package will enable these integrations
                  to function effectively, ensuring a smoother, more streamlined
                  process.
                </div>

                <a href="https://calendly.com/pricing" target="_blank">
                  View Calendly Pricing here
                </a>
              </>
            }
            banner
            closable
            onClose={() => {
              localStorage.closedCalendlySubscriptionInfo = "true";
            }}
          />
        )}

      <SMTPModalComponent
        SMTPModal={SMTPModal}
        setSMTPModal={setSMTPModal}
        smtp={smtp}
        setSmtp={setSmtp}
        onFinish={() => {
          document.dispatchEvent(new CustomEvent("REFRESH.PROFILE"));
        }}
      />
    </>
  );
};

export default Warnings;
