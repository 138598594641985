import { Divider } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectLoading } from "../../../redux/auth/selectors";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";
import { Button } from "../../Landing/Button";

const PAGINATION_LIMIT = 9;

const CreateVacancy = () => {
  const navigate = useNavigate();
  const [vacancyTemplates, setVacancyTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const pageLoading = useSelector(selectLoading);

  const loadMoreVacancies = useCallback(
    async (filters = {}, text = "", alternativePage) => {
      setLoading(true);
      try {
        const data = {
          filters: { ...filters },
        };
        if (text) data.text = text;
        const response = await CrudService.search(
          "VacancyTemplate",
          PAGINATION_LIMIT,
          alternativePage ?? page,
          data
        );
        const newVacancies = response.data.items;
        setTotal(response.data.total);
        setVacancyTemplates((prevVacancies) => [
          ...prevVacancies,
          ...newVacancies,
        ]);
        setPage((prevPage) => prevPage + 1);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [page]
  );

  useEffect(() => {
    if (loading) return;
    const handleScroll = () => {
      const container = document.getElementById("vacancyTemplateContainer");

      if (
        container &&
        window.innerHeight + window.scrollY >= container.scrollHeight - 100
      ) {
        loadMoreVacancies({}, searchTerm);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, loading, searchTerm]);

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setPage(1);
      setVacancyTemplates([]);

      const query = {};

      loadMoreVacancies(query, newValue, 1);
    }, 1000);
  };

  useEffect(() => {
    loadMoreVacancies();
  }, []);

  return (
    <>
      <div className="container mx-auto p-4" id="vacancyTemplateContainer">
        <div className="mt-2 flex items-center flex-col">
          <button
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
            onClick={async () => {
              if (pageLoading) return;
              const vacancy = await ATSService.createVacancy({
                vacancyTemplate: {
                  name: "",
                  description: "",
                  image: "",
                  keyBenefits: "",
                  requiredSkills: "",
                  published: false,
                },
              });
              navigate(
                `/dashboard/vacancyprepublish?id=${vacancy.data.vacancy._id}`
              );
            }}
          >
            Start from scratch
          </button>

          <Divider>or choose a template below</Divider>
        </div>

        <div className="relative mt-2 flex items-center">
          <input
            type="text"
            placeholder="Search Programs"
            className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900  dark:bg-gray-900"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
          {vacancyTemplates.map((vacancyTemplate) => (
            <div
              key={vacancyTemplate._id}
              className="max-w-sm rounded-xl overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl transition duration-300 ease-in-out"
            >
              <img className="w-full" src={vacancyTemplate.image} alt="" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">
                  {vacancyTemplate.name}
                </div>
                <p className="text-gray-700 dark:text-gray-300  text-base">
                  <strong>Description:</strong> {vacancyTemplate.description}
                </p>
                <p className="text-gray-700 dark:text-gray-300  text-base">
                  <strong>Key Benefits:</strong> {vacancyTemplate.keyBenefits}
                </p>
                <p className="text-gray-700 dark:text-gray-300  text-base">
                  <strong>Required Skills:</strong>{" "}
                  {vacancyTemplate.requiredSkills}
                </p>
              </div>
              <div className="px-6 pt-4 pb-2">
                <button
                  className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                  onClick={async () => {
                    if (pageLoading) return;
                    const vacancy = await ATSService.createVacancy({
                      vacancyTemplate,
                    });
                    navigate(
                      `/dashboard/vacancyprepublish?id=${vacancy.data.vacancy._id}`
                    );
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {total >= PAGINATION_LIMIT * page && (
        <div className="flex justify-center mt-5">
          <Button
            loading={loading}
            onClick={() => loadMoreVacancies({}, searchTerm)}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default CreateVacancy;
