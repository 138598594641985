import { Badge, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import CrudService from "../../../service/CrudService";
import ManageCandidates from "./ManageCandidates";
import SearchCandidate from "./SearchCandidate";

const CandidateSourcing = () => {
  const [totalLeads, setTotalLeads] = useState(0);
  const fetchData = async () => {
    try {
      const response = await CrudService.search("ApolloPeople", 1, 1, {});
      setTotalLeads(response.data?.total ?? 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    document.addEventListener("APOLLO_PEOPLE_UPDATE", fetchData);
    return () =>
      document.removeEventListener("APOLLO_PEOPLE_UPDATE", fetchData);
  }, []);

  const items = [
    {
      key: "1",
      label: "Search",
      children: <SearchCandidate />,
    },
    {
      key: "2",
      label: (
        <Space>
          <div>Leads</div>
          <Badge count={totalLeads} overflowCount={9999} offset={[0, 0]} />
        </Space>
      ),
      children: <ManageCandidates />,
    },
  ];

  return (
    <>
      <h1 className="font-bold mb-4 text-lg">Candidate Sourcing</h1>

      <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane />
    </>
  );
};

export default CandidateSourcing;
