import { Allotment } from "allotment";
import "allotment/dist/style.css";
import {
  Alert,
  Divider,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaCopy, FaExternalLinkAlt } from "react-icons/fa";
import { GrInfo } from "react-icons/gr";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloudinaryUpload from "../../../components/CloudinaryUpload";
import CrudService from "../../../service/CrudService";
import ATS from "./ATS";

const VacancyDetails = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyData(null);

    CrudService.getSingle("Vacancy", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [searchParams]);

  if (!vacancyData) return <Skeleton active />;
  return (
    <>
      {vacancyData && (
        <>
          <div
            className="mb-2 flex justify-start gap-2 items-center cursor-pointer text-sm"
            onClick={() => navigate("/dashboard/myvacancies")}
          >
            <MdArrowBackIos size={13} /> <div>Back to Funnels</div>
          </div>
          <h1 className="font-bold mb-4 text-lg">
            ATS: {vacancyData?.alternativeName || vacancyData?.name}
          </h1>
        </>
      )}

      <ATS VacancyId={vacancyData._id} />
    </>
  );
};

export default VacancyDetails;
