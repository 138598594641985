import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPartner } from "../../redux/auth/selectors";

const Tutorials = () => {
  const partner = useSelector(getPartner);

  const [activeTutorial, setActiveTutorial] = useState(null);

  useEffect(() => {
    if (!partner?.tutorials) return;

    setActiveTutorial(partner.tutorials?.[0]?._id);
  }, [partner]);

  if (!partner?.tutorials) return <Skeleton active />;
  if (!activeTutorial) return <Skeleton active />;
  return (
    <div className="max-w-full overflow-auto">
      <div id="playlist">
        <div id="video-dis">
          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              src={`https://www.loom.com/embed/${
                partner?.tutorials?.find?.((e) => e._id === activeTutorial)?.src
              }`}
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </div>
        <div id="v-list" className="video-li">
          <div id="vli-info">
            <div id="upper-info">
              <div id="li-titles">
                <div className="video-title">Tutorials</div>
                <div className="sub-title"></div>
              </div>
              <div id="drop-icon" />
            </div>
            <div id="lower-info">
              <div id="btn-repeat" />
              <div id="btn-suffle" />
              <div id="btn-save" />
            </div>
          </div>
          <div id="vli-videos">
            {partner.tutorials.map((tutorial, i) => (
              <div
                key={tutorial._id}
                onClick={() => setActiveTutorial(tutorial._id)}
                className="video-con active-con"
                video="https://www.youtube.com/embed/BVyTt3QJfIA"
              >
                <div className="index video-title">{i + 1}</div>
                <div className="video-thumb">
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        height: 0,
                      }}
                    >
                      <div class="iframe-container-blocker">
                        <iframe
                          src={`https://www.loom.com/embed/${tutorial.src}`}
                          allowFullScreen
                          allow="autoplay; fullscreen"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="v-titles">
                  <div className="video-title">{tutorial.title}</div>
                  <div className="sub-video-title">{/* SUBTITLE HERE */}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
