import { Alert, Skeleton, Space, Spin, Switch, message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { FaRedo, FaUndo } from "react-icons/fa";
import { TbRobotFace } from "react-icons/tb";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import { MINIMUM_AI_CHARS } from "../../../data/constants";
import { getPartner } from "../../../redux/auth/selectors";
import ApolloService from "../../../service/ApolloService";
import classNamesBody from "../Message/body.module.css";
import classNames from "../Message/example.module.css";
import useHistory from "../Message/useHistory";

const correctText = (text) =>
  text
    .replace(
      /\[Prospect's Firstname\]/g,
      "@[Prospect's Firstname](prospectFirstname)"
    )
    .replace(
      /\[Prospect's Lastname\]/g,
      "@[Prospect's Lastname](prospectLastname)"
    )
    .replace(/\[Prospect's Email\]/g, "@[Prospect's Email](prospectEmail)")
    .replace(/\[Company Name\]/g, "@[Company Name](companyName)")
    .replace(/\[Company Website\]/g, "@[Company Website](companyWebsite)")
    .replace(/\[Company Address\]/g, "@[Company Address](companyAddress)")
    .replace(
      /\[User Calendar Link\]/g,
      "@[User Calendar Link](userCalendarLink)"
    )
    .replace(/\[Current Date\]/g, "@[Current Date](currentDate)")
    .replace(/\[Current Time\]/g, "@[Current Time](currentTime)")
    .replace(/\[User Firstname\]/g, "@[User Firstname](userFirstname)")
    .replace(/\[User Lastname\]/g, "@[User Lastname](userLastname)")
    .replace(/\[User Email\]/g, "@[User Email](userEmail)")
    .replace(/\[User Phone\]/g, "@[User Phone](userPhone)");

const ContactMessage = ({ onSend, recipientData }) => {
  const [subject, setSubject] = useState("");
  const {
    state: body,
    setState: setBody,
    undo: undoBody,
    redo: redoBody,
  } = useHistory("");
  const [includeBCC, setIncludeBcc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [AILoading, setAILoading] = useState(false);
  const socket = useRef(null);
  const socketPing = useRef(null);
  const partner = useSelector(getPartner);

  const handleReset = () => {
    onSend(subject, body);
    setSubject("");
    setBody("");
    setIncludeBcc(false);
  };

  const aiOutreachGenerator = useCallback(async () => {
    if (AILoading) return;
    if (socket.current) socket.current.close();

    socket.current = new WebSocket(
      `wss://booklified-chat-socket.herokuapp.com`
    );

    socket.current.addEventListener("open", async () => {
      socketPing.current = setInterval(
        () => socket.current.send(JSON.stringify({ id: "PING" })),
        30000
      );
      const content = `Hello, I need your expertise in creating personal outreach messages that are engaging and have high open and reply rates. Include in the message a few sentences on what peaked my interest on their employment history, and elaborate on that.
      
      Here is the data of my prospect:
      ${JSON.stringify(recipientData)}
      
      ________
      You need to use the following structure for variables: [Prospect's Firstname]
      
      Here is a list of specified variables:
      [
          { id: "prospectFirstname", display: "Prospect's Firstname" },
          { id: "prospectLastname", display: "Prospect's Lastname" },
          { id: "prospectEmail", display: "Prospect's Email" },
          { id: "companyName", display: "Company Name" },
          { id: "companyWebsite", display: "Company Website" },
          { id: "companyAddress", display: "Company Address" },
          { id: "userCalendarLink", display: "User Calendar Link" },
          { id: "currentDate", display: "Current Date" },
          { id: "currentTime", display: "Current Time" },
          { id: "userFirstname", display: "User Firstname" },
                    { id: "candidateToken", display: "Candidate Token" },
          { id: "userLastname", display: "User Lastname" },
          { id: "userEmail", display: "User Email" },
          { id: "userPhone", display: "User Phone" },
      ]
              
      It is imperative that you DO NOT use any variables other than the ones specified.
      
      It is imperative to use the exact format as it is written here. Here are some possible examples of how a variable can look like inside your text:
      [User Email]
      [Company Name]
      [Prospect's Firstname]
      [Prospect's Phone]
              
      It is imperative that your reply contains nothing beyond the following data structure in JSON. Do not add anything else into your answer.

      It is imperative that you do not include "I hope ..." or "I hope this emails find you ..." opening, and instead come up with a creative opening line. It is imperative to NOT use "I hope" in the email!
      Make the email sound less like it was written by AI!

      {
        subject: "An email subject line chosen carefully to increase the open rate",
        body: "The body of email generated according to above instructions"
      }
              `;

      setAILoading(true);
      socket.current.send(
        JSON.stringify({
          id: "OPEN_AI_PROMPT",
          payload: {
            content,
            model: "gpt-3.5-turbo-16k",
            partner: partner._id,
          },
        })
      );
    });

    socket.current.addEventListener("message", async (event) => {
      const message = JSON.parse(event.data);
      const response = message.payload?.response;

      const { body, subject } = JSON.parse(response);

      setSubject(correctText(subject));
      setBody(correctText(body));
      setAILoading(false);
      if (socketPing.current) clearInterval(socketPing.current);
    });
  }, [recipientData, AILoading, socket]);

  if (!recipientData) return <Skeleton active />;
  return (
    <>
      <div>
        <Space size={20}>
          <label className="font-bold">Include me in BCC</label>
          <Switch checked={includeBCC} onChange={(e) => setIncludeBcc(e)} />
        </Space>
      </div>

      {recipientData && (
        <>
          <br />
          <label className="font-bold">Recipient</label>
          <div>
            {recipientData?.first_name} {recipientData?.last_name}{" "}
            <a
              href={`mailto:${recipientData?.email}`}
            >{`<${recipientData?.email}>`}</a>
          </div>
        </>
      )}

      <br />

      <label className="font-bold">Subject</label>
      <MentionsInput
        placeholder="Type # to browse variables"
        value={subject}
        onChange={(_, value) => setSubject(value)}
        classNames={classNames}
        a11ySuggestionsListLabel={"Possible variables"}
      >
        <Mention
          trigger="#"
          className={"bg-indigo-100"}
          data={[
            { id: "prospectFirstname", display: "Prospect's Firstname" },
            { id: "prospectLastname", display: "Prospect's Lastname" },
            { id: "prospectEmail", display: "Prospect's Email" },
            { id: "companyName", display: "Company Name" },
            { id: "companyWebsite", display: "Company Website" },
            { id: "companyAddress", display: "Company Address" },
            { id: "userCalendarLink", display: "User Calendar Link" },
            { id: "currentDate", display: "Current Date" },
            { id: "currentTime", display: "Current Time" },
            { id: "userFirstname", display: "User Firstname" },
            { id: "candidateToken", display: "Candidate Token" },
            { id: "userLastname", display: "User Lastname" },
            { id: "userEmail", display: "User Email" },
            { id: "userPhone", display: "User Phone" },
          ]}
        />
      </MentionsInput>

      <br />

      <div className="flex justify-between">
        <label className="font-bold">Message</label>

        <Space>
          <button
            className="px-2 py-1 text-sm bg-white-500 text-indigo-500 border border-indigo-500 rounded"
            onClick={undoBody}
            type="secondary"
          >
            <FaUndo />
          </button>
          <button
            className="px-2 py-1 text-sm bg-white-500 text-indigo-500 border border-indigo-500 rounded"
            onClick={redoBody}
            type="secondary"
          >
            <FaRedo />
          </button>
          {AILoading && <Spin />}
          <TbRobotFace
            loading={AILoading}
            size={18}
            className="cursor-pointer"
            onClick={() => {
              if (AILoading) return;
              if (!body) return message.info("Please write some text first");
              if (body?.length < MINIMUM_AI_CHARS)
                return message.info(
                  `AI needs a little more context. Please write at least ${MINIMUM_AI_CHARS} characters.`
                );

              if (socket.current) socket.current.close();

              socket.current = new WebSocket(
                `wss://booklified-chat-socket.herokuapp.com`
              );

              socket.current.addEventListener("open", async () => {
                socketPing.current = setInterval(
                  () => socket.current.send(JSON.stringify({ id: "PING" })),
                  30000
                );
                const content = `Hello, I need your expertise in transforming the following email text into a highly professional version. Please apply your literary skills to rewrite this text. Elevate its language, make it more engaging to read. Here's the text:
                
                ${body}
                
                ________
                You need to use the following structure for variables: [Prospect's Firstname]
                
                Here is a list of specified variables:
                [
                    { id: "prospectFirstname", display: "Prospect's Firstname" },
                    { id: "prospectLastname", display: "Prospect's Lastname" },
                    { id: "prospectEmail", display: "Prospect's Email" },
                    { id: "companyName", display: "Company Name" },
                    { id: "companyWebsite", display: "Company Website" },
                    { id: "companyAddress", display: "Company Address" },
                    { id: "userCalendarLink", display: "User Calendar Link" },
                    { id: "currentDate", display: "Current Date" },
                    { id: "currentTime", display: "Current Time" },
                    { id: "userFirstname", display: "User Firstname" },
                    { id: "candidateToken", display: "Candidate Token" },
                    { id: "userLastname", display: "User Lastname" },
                    { id: "userEmail", display: "User Email" },
                    { id: "userPhone", display: "User Phone" },
                ]
                        
                It is imperative that you DO NOT use any variables other than the ones specified.
                
                It is imperative to use the exact format as it is written here. Here are some possible examples of how a variable can look like inside your text:
                [User Email]
                [Company Name]
                [Prospect's Firstname]
                [Prospect's Phone]

                It is imperative that you do not include "I hope ..." or "I hope this emails find you ..." opening, and instead come up with a creative opening line. It is imperative to NOT use "I hope" in the email!
                Make the email sound less like it was written by AI!          
                
                It is imperative that your reply contains nothing beyond the upgraded text. Please only answer with the enhanced version of the text. Do not add anything else into your answer.
                        `;

                setAILoading(true);
                socket.current.send(
                  JSON.stringify({
                    id: "OPEN_AI_PROMPT",
                    payload: {
                      content,
                      model: "gpt-3.5-turbo-16k",
                      partner: partner._id,
                    },
                  })
                );
              });

              socket.current.addEventListener("message", async (event) => {
                const message = JSON.parse(event.data);
                const response = message.payload?.response;

                setBody(correctText(response));
                setAILoading(false);
                if (socketPing.current) clearInterval(socketPing.current);
              });
            }}
          />
        </Space>
      </div>
      <MentionsInput
        placeholder="Type # to browse variables"
        value={body}
        onChange={(_, value) => setBody(value)}
        classNames={classNamesBody}
        a11ySuggestionsListLabel={"Possible variables"}
      >
        <Mention
          trigger="#"
          className={"bg-indigo-100"}
          data={[
            { id: "prospectFirstname", display: "Prospect's Firstname" },
            { id: "prospectLastname", display: "Prospect's Lastname" },
            { id: "prospectEmail", display: "Prospect's Email" },
            { id: "companyName", display: "Company Name" },
            { id: "companyWebsite", display: "Company Website" },
            { id: "companyAddress", display: "Company Address" },
            { id: "userCalendarLink", display: "User Calendar Link" },
            { id: "currentDate", display: "Current Date" },
            { id: "currentTime", display: "Current Time" },
            { id: "userFirstname", display: "User Firstname" },
            { id: "candidateToken", display: "Candidate Token" },
            { id: "userLastname", display: "User Lastname" },
            { id: "userEmail", display: "User Email" },
            { id: "userPhone", display: "User Phone" },
          ]}
        />
      </MentionsInput>

      {!localStorage?.informationMessageVariables001 && (
        <Alert
          type="info"
          message="To explore available messaging variables, simply type '#' followed by your keyword, and a list of suggestions will appear for your convenience."
          closable
          onClose={() => (localStorage.informationMessageVariables001 = "true")}
        />
      )}
      <br />

      <div className="flex items-center justify-between">
        <div />
        <Space>
          <button
            onClick={aiOutreachGenerator}
            className="px-2 py-1 text-sm bg-transparent border border-indigo-500 text-indigo-500 rounded mt-5"
            disabled={loading || AILoading}
          >
            {!AILoading ? "AI Outreach" : <Spin>AI Outreach</Spin>}
          </button>
          <button
            onClick={async () => {
              if (!subject) return message.error("Please enter a subject line");
              if (!body) return message.error("Message cannot be empty");

              setLoading(true);
              try {
                if (recipientData)
                  await ApolloService.messageProspect({
                    recipientData,
                    subject: subject,
                    message: body,
                    includeBCC,
                  });
                handleReset();
              } catch (e) {
              } finally {
                setLoading(false);
              }
            }}
            className="px-2 py-1 text-sm bg-indigo-500 text-white rounded mt-5"
            disabled={loading}
          >
            {!loading ? "Send" : <Spin>Send</Spin>}
          </button>
        </Space>
      </div>
    </>
  );
};

export default ContactMessage;
