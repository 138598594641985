import { Skeleton } from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import EmailVerify from "./EmailVerify";
import JobPortal from "./JobPortal";
import JoinTeamMember from "./JoinTeamMember";
import PublicPages from "./PublicPages";
import { ZOOM_ENABLED, brandColor } from "./data/constants";
import CandidateCV from "./pages/CandidateCV";
import Preview from "./pages/CandidateCV/Preview";
import Dashboard, {
  changeIndigoShades,
  generateTailwindPalette,
} from "./pages/Dashboard";
import Calendly from "./pages/Dashboard/oauth/Calendly";
import Zoom from "./pages/Dashboard/oauth/Zoom";
import Funnel from "./pages/Funnel";
import HiringManagerSign from "./pages/HiringManagerSign";
import Offer from "./pages/Offer";
import SurveyDetails from "./pages/Survey";
import PartnerRegistration from "./pages/auth/PartnerRegistration";
import { login, setPartner } from "./redux/auth/actions";
import { getPartner, selectDarkMode } from "./redux/auth/selectors";
import { store } from "./redux/store";
import AuthService from "./service/AuthService";
import PublicService from "./service/PublicService";
import UserService from "./service/UserService";

function extractMongoDBId(inputString) {
  const objectIdPattern = /[0-9a-fA-F]{24}/;
  const match = inputString.match(objectIdPattern);

  if (match) {
    return match[0];
  } else {
    return null; // No valid MongoDB ObjectID found in the input string
  }
}

const RouteInit = () => {
  const location = useLocation();

  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);

  const [loading, setLoading] = useState(true);
  const [registered, setRegistered] = useState(true);

  useEffect(() => {
    var script;

    if (partner?.termlyConsentBannerID) {
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://app.termly.io/resource-blocker/${partner.termlyConsentBannerID}?autoBlock=on`;

      document.head.appendChild(script);
    }

    if (partner?.microsoftClarityID) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", partner?.microsoftClarityID);
    }
    if (partner?.zohoIqId) {
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: partner?.zohoIqId,
        values: {},
        ready: function () {},
      };

      // Create the script element
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "zsiqchat";
      script.defer = true;
      script.src = "https://salesiq.zohopublic.eu/widget";
      // Note: No need to set script.text here as the configuration is already defined

      // Append the script to the document head
      document.head.appendChild(script);
    }
    return () => {
      if (script) document.head.removeChild(script);
    };
  }, [partner]);

  useEffect(() => {
    setLoading(!partner);

    if (!partner) return;
    changeIndigoShades(
      generateTailwindPalette(partner?.themeColor ?? brandColor)
    );

    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = partner.favicon;

    // Set the apple-touch-icon dynamically
    const appleTouchIcon = document.querySelector(
      'link[rel="apple-touch-icon"]'
    );
    appleTouchIcon.href = partner.logo;

    document.title = partner.siteTitle.replace(
      /\{brandName\}/g,
      partner.brandName
    );

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        partner.metaDescription.replace(/\{brandName\}/g, partner.brandName)
      );
  }, [partner]);

  useEffect(() => {
    if (
      [
        "/funnel",
        "/cv",
        "/cvPreview",
        "/offer",
        "/hiringManagerSign",
        "/jobportal",
        "/survey",
      ].includes(location.pathname)
    ) {
      let mongoId,
        candidateId = null;

      mongoId =
        extractMongoDBId(location.pathname) ||
        extractMongoDBId(location.search);
      if (location.search.includes("?token=")) {
        const token = location?.search?.split?.("?token=")?.[1];
        const decodedToken = jwt_decode(token);
        candidateId = decodedToken.candidateId;
      }

      if (mongoId || candidateId)
        PublicService.getRecruiterData(mongoId, candidateId).then(
          ({ data }) => {
            if (!data?.recruiter?.themeColor) return;
            changeIndigoShades(
              generateTailwindPalette(
                data?.recruiter?.themeColor ||
                  (partner?.themeColor ?? brandColor)
              )
            );
          }
        );
    } else {
      changeIndigoShades(
        generateTailwindPalette(partner?.themeColor ?? brandColor)
      );
    }
  }, [location, partner]);

  const handleRefresh = () => {
    PublicService.getPartnerConfig().then((res) => {
      if (!res.data.registered) setRegistered(false);
      store.dispatch(setPartner(res.data.partner));
      localStorage.setItem(
        "cloudinaryAPIKey",
        res.data.partner.cloudinaryAPIKey
      );
      localStorage.setItem(
        "cloudinaryCloudName",
        res.data.partner.cloudinaryCloudName
      );
      localStorage.setItem(
        "cloudinaryPreset",
        res.data.partner.cloudinaryPreset
      );
    });

    if (Cookies.get("accessToken"))
      AuthService.me().then((res) => {
        store.dispatch(login(res.data.me));
      });
  };
  useEffect(() => {
    handleRefresh();
  }, [location]);

  useEffect(() => {
    document.addEventListener("REFRESH.PROFILE", handleRefresh);
    return () => document.removeEventListener("REFRESH.PROFILE", handleRefresh);
  }, [handleRefresh]);

  const onFinishPartnerRegister = () => setRegistered(true);

  if (!registered)
    return (
      <PartnerRegistration onFinishPartnerRegister={onFinishPartnerRegister} />
    );
  if (loading && !partner)
    return (
      <div
        style={{
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="splash"
          style={{
            backgroundImage: "url('/images/background-auth.jpg')",
            height: "110vh",
            width: "110vw",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            margin: -30,
            filter: "blur(30px)",
            boxShadow: "0 0 100vh 100vw rgba(0,0,0,0.25) inset",
          }}
        ></div>
      </div>
    );

  return (
    <div className="min-h-[100vh] dark:bg-gray-900 dark:text-gray-400">
      <Routes>
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route
          path="/funnel"
          element={
            <div className={`${darkMode ? "dark" : ""}`}>
              <Funnel />
            </div>
          }
        />
        <Route path="/cv" element={<CandidateCV />} />
        <Route path="/cvPreview" element={<Preview />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/hiringManagerSign" element={<HiringManagerSign />} />
        <Route path="/emailVerify" element={<EmailVerify />} />
        <Route path="/join" element={<JoinTeamMember />} />
        <Route path="/jobportal/:recruiterId" element={<JobPortal />} />
        <Route path="/survey" element={<SurveyDetails />} />
        <Route path={"/calendly"} element={<Calendly />} />
        {ZOOM_ENABLED && <Route path={"/zoomAuth"} element={<Zoom />} />}
        <Route path="/*" element={<PublicPages />} />
      </Routes>
    </div>
  );
};

export default RouteInit;
