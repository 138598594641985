import { CheckIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencies } from "../../data/currencies";
import { getPartner } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";

const includedFeatures = [
  "Unlock full software capabilities",
  "Accept new user registrations",
  "Immediate software activation",
  "Consultation",
  "Live partner events",
];

const PartnerActivation = () => {
  const [partnerActivated, setPartnerActivated] = useState(false);
  const [loading, setLoading] = useState(true);
  const partner = useSelector(getPartner);

  const handleSubmit = async () => {
    const res = await AuthService.partnerActivation({
      return_url: window.location.href,
    });
    window.location.href = res.data.link;
  };

  useEffect(() => {
    AuthService.getPartnerActivated().then((data) => {
      setPartnerActivated(!!data.data.activated);
      setLoading(false);
    });
  }, []);

  if (loading) return <Skeleton active />;

  if (partnerActivated)
    return (
      <>
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                License Activated
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  Congratulations! Your software license has been activated.
                </p>
              </div>
            </div>
          </div>
        </div>

        <a
          onClick={handleSubmit}
          className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer"
        >
          Billing
        </a>
      </>
    );

  return (
    <>
      <div className="bg-white dark:bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-400  sm:text-4xl">
              Unlock Your Full Software License
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Become a full-fledged partner and leverage the power of our
              software. After signing up, your software distribution will start
              accepting new user registrations and operate at full capacity.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400 ">
                Lifetime Partnership
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                To activate your software license, it's necessary to keep your
                payment information on file. Rest assured, no additional charges
                will be applied beyond the license fee specified in the signed
                agreement.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-gray-100 dark:bg-gray-900" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 dark:bg-gray-800">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    One-time activation fee
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-400 ">
                      {currencies.find((c) => c.iso === partner?.currency)
                        ?.symbol ?? "$"}
                      0
                    </span>
                  </p>
                  <a
                    onClick={handleSubmit}
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer"
                  >
                    Activate License
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Activate your license for free. Your SaaS will be ready to
                    go, with full capabilities unlocked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerActivation;
