import { CheckIcon } from "@heroicons/react/24/outline";
import { Popconfirm, Table } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencies } from "../../../data/currencies";
import { getPartner } from "../../../redux/auth/selectors";
import RCService from "../../../service/RCService";

const columns = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    // Add any render/formatting logic here
    render: (text) => new Date(text).toLocaleString(),
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
  },
  {
    title: "Change",
    dataIndex: "change",
    key: "change",
    render: (text, record) => (
      <span className={text < 0 ? "text-red-500" : "text-green-500"}>
        {text.toFixed(2)}
      </span>
    ),
  },
  // Add more columns as needed
];

const getIsValidPurchaseAmount = (e) => {
  const number = parseFloat(e);
  if (isNaN(number)) return false;

  return true;
};

const PAGE_SIZE = 5;

const RCCredits = () => {
  const partner = useSelector(getPartner);
  const [requestPurchaseAmount, setRequestPurchaseAmount] = useState("");
  const [currentRC, setCurrentRC] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
  });

  const reloadData = async (page = 1) => {
    RCService.getCurrentRC().then(({ data }) => {
      setCurrentRC(data.number);
    });
    RCService.getTransactions(PAGE_SIZE, page).then(({ data }) => {
      setTransactions(data.transactions);
      setPagination((pagination) => ({
        ...pagination,
        current: page,
        total: data.total,
      }));
    });
  };
  useEffect(() => {
    reloadData();
  }, []);
  const handleTableChange = (pagination) => {
    reloadData(pagination.current);
  };

  return (
    <>
      <h1 className="font-bold text-lg">Rechargeable Credits (RC)</h1>
      <p>
        Welcome to our convenient Rechargeable Credits (RC) system, designed to
        keep you seamlessly connected with your candidates.
      </p>
      <p>
        <strong>Starting Balance:</strong> As a new user, you begin with{" "}
        {partner?.default_rcCredit} RCs.
      </p>
      <p>
        <strong>Purchase RCs:</strong> You can purchase additional credits
        anytime - buy as few as {partner?.default_minimumRCPurchase} or as many
        as {partner?.default_maximumRCPurchase} RCs in one transaction. Each RC
        costs{" "}
        {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
        {partner?.default_rcToDollarExchange}.
      </p>
      <h2 className="font-bold text-md mt-5">Usage Rates:</h2>
      <ul role="list" className="text-sm leading-6 text-gray-600 mb-5">
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Reach out to candidates via SMS for {partner?.smsRCCost?.toFixed?.(2)}{" "}
          RC per message
        </li>
        <li className="flex gap-x-3">
          <CheckIcon
            className="h-6 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          Make calls with your candidates for{" "}
          {partner?.minuteCallRCCost?.toFixed?.(2)} RC per minute
        </li>
      </ul>
      <p>
        Reliable Deduction: Rest assured, your actions will accurately decrease
        your RC balance, reflecting your usage transparently. You can track a
        history of your credits on this page.
      </p>
      <p>
        Stay connected, communicate effectively, and enhance your recruitment
        process with our RC system.
      </p>
      <p>Happy Recruiting!</p>

      <div className="mt-10">
        <h1 className="font-bold text-md">Transactions</h1>
        <Table
          columns={columns}
          dataSource={transactions}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>

      <div className="mt-10">
        <div className="flex flex-wrap items-baseline justify-between gap-y-2 bg-white dark:bg-gray-900  py-10 ">
          <dt className="text-sm font-medium leading-6 text-gray-500">
            Your current balance
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-gray-400 ">
            {currentRC?.toFixed?.(2)} RC
          </dd>
        </div>

        <div className="relative mt-2 rounded-md shadow-sm dark:shadow-gray-400/50 ">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
          <input
            type="text"
            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-gray-400  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            placeholder="Enter RC amount to purchase"
            value={requestPurchaseAmount}
            onChange={(e) => setRequestPurchaseAmount(e.target.value)}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              RC
            </span>
          </div>
        </div>
        <div className="relative mt-2 rounded-md shadow-sm dark:shadow-gray-400/50 ">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">
              {currencies.find((c) => c.iso === partner?.currency)?.symbol ??
                "$"}
            </span>
          </div>
          <input
            type="text"
            className="bg-gray-200 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 dark:text-gray-400  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            placeholder="0.00"
            disabled
            value={
              getIsValidPurchaseAmount(requestPurchaseAmount)
                ? `${(
                    parseFloat(requestPurchaseAmount) *
                    (partner?.default_rcToDollarExchange ?? 1)
                  ).toFixed(2)}`
                : ""
            }
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {partner?.currency?.toUpperCase?.()}
            </span>
          </div>
        </div>
      </div>

      <Popconfirm
        title={`Are you sure to purchase ${requestPurchaseAmount} RC for ${
          currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"
        }${
          parseFloat(requestPurchaseAmount) *
          (partner?.default_rcToDollarExchange ?? 1)
        }?`}
        onConfirm={async (e) => {
          const number = parseFloat(requestPurchaseAmount);
          try {
            await RCService.purchaseRC({ number });
            await reloadData();
          } catch (e) {
            const link = e?.response?.data?.link;
            if (link)
              setTimeout(() => {
                window.location.href = link;
              }, 2000);
          }
        }}
      >
        <button
          disabled={!getIsValidPurchaseAmount(requestPurchaseAmount)}
          className={classNames(
            "bg-indigo-500 text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-400 focus-visible:outline-indigo-500",
            "block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mt-2 w-full"
          )}
        >
          Purchase RC
        </button>
      </Popconfirm>
    </>
  );
};

export default RCCredits;
