import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  Badge,
  Button,
  Modal,
  Popconfirm,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { ImStatsDots } from "react-icons/im";
import { IoMegaphone } from "react-icons/io5";
import { MdDelete, MdEdit, MdFileCopy, MdPreview } from "react-icons/md";
import { PiKanbanFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { brandColor } from "../../../data/constants";
import {
  getPartner,
  selectDarkMode,
  selectUser,
} from "../../../redux/auth/selectors";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";

const tiers = [
  {
    name: "Hire Fast",
    id: "fast",
    price: 45,
    description: "Promote for a day",
    days: 1,
    features: ["10-100 candidates", "Live for 1 days", "Fill the vacancy fast"],
  },
  {
    name: "Hire Better",
    id: "better",
    price: 295,
    description: "Promote for a week",
    days: 7,
    features: [
      "100-1000 candidates",
      "Live for 7 days",
      "Find a great fit for the position",
    ],
  },
  {
    name: "Hire Best",
    id: "best",
    price: 595,
    description: "Promote for 2 weeks",
    days: 14,
    features: [
      "600-2000 candidates",
      "Live for 14 days",
      "Find the best fit for the position",
    ],
  },
];

function truncateString(str, num) {
  if (!str) return "";
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

function removeDuplicateObjects(arr) {
  const uniqueObjects = {};
  const result = [];

  for (const obj of arr) {
    const id = obj._id;

    // Check if the _id is not already in the uniqueObjects dictionary
    if (!uniqueObjects[id]) {
      uniqueObjects[id] = true;
      result.push(obj);
    }
  }

  return result;
}

const PAGE_LIMIT = 9;

const MyVacancies = () => {
  const user = useSelector(selectUser);
  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState([]);
  const [singleVacancy, setSingleVacancy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [promoteFunnel, setPromoteFunnel] = useState(null);
  const [total, setTotal] = useState(0);
  const [activePromotions, setActivePromotions] = useState([]);

  const loadMoreVacancies = useCallback(
    async (filters = {}, text = "", alternativePage) => {
      if (!user) return;

      setLoading(true);
      try {
        const data = {
          filters: { ...filters, user_id: user._id },
          sort: { createdAt: -1 },
        };
        if (text) data.text = text;
        const response = await CrudService.search(
          "Vacancy",
          PAGE_LIMIT,
          alternativePage ?? page,
          data
        );
        const newVacancies = (
          await ATSService.countApplicants(response.data.items)
        ).data;
        setVacancies((prevVacancies) => [...prevVacancies, ...newVacancies]);
        setPage((prevPage) => prevPage + 1);
        setTotal(response.data.total);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [page, user]
  );

  useEffect(() => {
    ATSService.getActivePromotions().then(({ data }) => {
      setActivePromotions(data.activePromotions);
    });
  }, []);

  useEffect(() => {
    if (loading) return;
    const handleScroll = () => {
      const container = document.getElementById("vacancyContainer");

      if (
        container &&
        window.innerHeight + window.scrollY >= container.scrollHeight - 100
      ) {
        loadMoreVacancies();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, loading]);

  useEffect(() => {
    const getSingle = async () => {
      const id = searchParams.get("id");
      const isNew = searchParams.get("new");
      if (!id) return;

      const searchedVacancy = await CrudService.getSingle("Vacancy", id);
      setSingleVacancy({ ...searchedVacancy.data, isNew: isNew === "true" });
    };
    getSingle();
  }, [searchParams]);

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setPage(1);
      setVacancies([]);

      const query = {};

      loadMoreVacancies(query, newValue, 1);
      setSingleVacancy(null);
    }, 1000);
  };

  useEffect(() => {
    loadMoreVacancies();
  }, []);

  const handleSecondConfirm = async (vacancyId, deleteCandidates) => {
    await ATSService.deleteFunnel(vacancyId, deleteCandidates);
    setSingleVacancy(null);
    setVacancies((c) => c.filter((e) => e._id !== vacancyId));
  };

  const handleFirstConfirm = (vacancyId) => {
    modal.confirm({
      title: "Candidate Management upon Funnel Deletion",
      content:
        "You are initiating the deletion of a recruitment funnel. Prior to proceeding, please select how you wish to manage the candidates associated with this funnel. You may opt to keep the candidate profiles within your overall ATS, or permanently remove them from the database.",
      okText: "Keep candidates",
      cancelText: "Delete candidates",
      closable: true,
      onOk: () => {
        modal.confirm({
          title: "Confirm Deletion",
          content:
            "Please confirm the deletion of this funnel. Be advised that since you choose to keep the candidates within your overall ATS, specific details such as the job title, job location, and job link associated with this funnel will no longer be available in messaging variables for these candidates. As an alternative to deletion, consider simply disabling the funnel to preserve its information. For disabling a funnel, you can use the switch right next to the job title.",
          okText: "DELETE",
          cancelText: "Cancel",
          closable: true,
          onOk: async () => {
            handleSecondConfirm(vacancyId, false);
          },
        });
      },
      onCancel: () => {
        modal.confirm({
          title: "Confirm Deletion",
          content:
            "Please confirm that you want to delete this funnel and delete all of the associated candidates.",
          okText: "DELETE",
          cancelText: "Cancel",
          closable: true,
          onOk: async () => {
            handleSecondConfirm(vacancyId, true);
          },
        });
      },
    });
  };

  return (
    <>
      {contextHolder}

      <div className="container mx-auto p-4" id="vacancyContainer">
        <div className="relative mt-2 flex items-center">
          <input
            type="text"
            placeholder="Search Programs"
            className="block w-full rounded-md border-0 py-1.5 pr-14  dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900  dark:bg-gray-900"
            value={searchTerm}
            onChange={handleInputChange}
            autoComplete="new-password"
          />
        </div>

        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 my-vacancies-grid mt-2">
          {removeDuplicateObjects(
            [singleVacancy, ...vacancies].filter((a) => !!a)
          ).map((vacancy) => (
            <Badge.Ribbon
              key={vacancy._id}
              size="small"
              style={{
                top: 60,
                display: vacancy?.isNew ? "block" : "none",
              }}
              text="New"
              color={partner?.themeColor ?? brandColor}
            >
              <div className="max-w-sm rounded-xl overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl transition duration-300 ease-in-out">
                <div className="px-3 py-3 flex gap-3 items-center justify-between">
                  <div className="font-bold text-xl mb-2 whitespace-nowrap max-w-[200px] overflow-hidden truncate">
                    <Typography.Paragraph
                      editable={{
                        onChange: (e) => {
                          setVacancies((c) => {
                            const current = [...c];
                            const thisOne = current.find(
                              (a) => a._id === vacancy._id
                            );
                            if (!thisOne) return current;
                            thisOne.alternativeName = e;

                            return current;
                          });
                          if (singleVacancy?._id === vacancy?._id)
                            setSingleVacancy((x) => ({
                              ...x,
                              alternativeName: e,
                            }));
                          CrudService.update("Vacancy", vacancy._id, {
                            alternativeName: e,
                          });
                        },
                        value: vacancy.alternativeName || vacancy.name,
                        tooltip: "Change funnel name",
                      }}
                    >
                      {truncateString(vacancy.alternativeName, 25) ||
                        truncateString(vacancy.name, 25) ||
                        "Unspecified"}
                    </Typography.Paragraph>
                  </div>
                  <Switch
                    size="small"
                    defaultChecked={vacancy.enabled !== false}
                    onChange={async (e) => {
                      await CrudService.update("Vacancy", vacancy._id, {
                        enabled: e,
                      });
                    }}
                  />
                </div>
                <div className="px-6 pb-4 gap-3 flex flex-col">
                  <div className="flex gap-3">
                    <MdPreview
                      title="Preview"
                      className="cursor-pointer text-indigo-500"
                      onClick={async () => {
                        window.open(`/funnel?id=${vacancy._id}`);
                      }}
                    />
                    <MdFileCopy
                      title="Copy Link"
                      className="cursor-pointer text-indigo-500"
                      onClick={async () => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/funnel?id=${vacancy._id}`
                        );
                        message.success("Copied to clipboard");
                      }}
                    />

                    <Badge
                      count={vacancy.numberApplicants}
                      offset={[0, -7]}
                      overflowCount={9999}
                      title="ATS"
                      className="cursor-pointer text-indigo-500"
                      onClick={async () => {
                        navigate(`/dashboard/vacancydetails?id=${vacancy._id}`);
                      }}
                    >
                      <PiKanbanFill title="ATS" />
                    </Badge>

                    <ImStatsDots
                      title="Statistics"
                      className="cursor-pointer text-indigo-500"
                      onClick={async () => {
                        navigate(`/dashboard/vacancystats?id=${vacancy._id}`);
                      }}
                    />

                    {partner?.currency === "USD" && (
                      <IoMegaphone
                        title="Promote"
                        className={`cursor-pointer ${
                          activePromotions.some(
                            (p) => p.VacancyId === vacancy._id
                          )
                            ? "text-green-500"
                            : "text-indigo-500"
                        }`}
                        onClick={() => {
                          if (
                            activePromotions.some(
                              (p) => p.VacancyId === vacancy._id
                            )
                          )
                            return;
                          setPromoteFunnel(vacancy._id);
                        }}
                      />
                    )}
                  </div>
                  <div className="flex gap-3">
                    <MdDelete
                      title="Delete"
                      onClick={() => handleFirstConfirm(vacancy._id)}
                      className="cursor-pointer text-red-500 "
                    />
                    <MdEdit
                      title="Edit"
                      className="cursor-pointer text-indigo-500"
                      onClick={async () => {
                        navigate(`/dashboard/vacancyedit?id=${vacancy._id}`);
                      }}
                    />
                    <Popconfirm
                      title="Are you sure to duplicate this funnel?"
                      onConfirm={async () => {
                        const vac = await ATSService.duplicateVacancy({
                          vacancyId: vacancy._id,
                        });
                        navigate(
                          `/dashboard/vacancydetails?id=${vac.data.vacancy._id}`
                        );
                      }}
                    >
                      <HiMiniDocumentDuplicate
                        title="Duplicate Funnel"
                        className="cursor-pointer text-indigo-500"
                      />
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </Badge.Ribbon>
          ))}
        </div>
      </div>

      {total >= PAGE_LIMIT * (page - 1) && (
        <div className="flex justify-center mt-5">
          <Button loading={loading} onClick={() => loadMoreVacancies()}>
            Load more
          </Button>
        </div>
      )}

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!promoteFunnel}
        onCancel={() => setPromoteFunnel(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <div>
          <div className="mx-auto max-w-7xl px-6 ">
            <div className="mt-20 flow-root">
              <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto  ">
                {tiers.map((tier) => (
                  <div key={tier.id} className="  pt-16  lg:pt-0 xl:px-14">
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 "
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-5xl font-bold tracking-tight ">
                        ${tier.price}
                      </span>
                    </p>

                    <Popconfirm
                      title={`Are you sure to promote? You will be charged $${tier.price}.`}
                      onConfirm={async () => {
                        try {
                          await ATSService.promoteFunnel(promoteFunnel, {
                            tier: tier.id,
                          });
                          ATSService.getActivePromotions().then(({ data }) => {
                            setActivePromotions(data.activePromotions);
                          });
                          setPromoteFunnel(null);
                        } catch (e) {
                          const link = e?.response?.data?.link;
                          if (link)
                            setTimeout(() => {
                              window.location.href = link;
                            }, 2000);
                        }
                      }}
                    >
                      <a
                        aria-describedby={tier.id}
                        className=" mt-10 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Promote now
                      </a>
                    </Popconfirm>
                    <p className="min-w-[100px] mt-10 text-sm font-semibold leading-6 ">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-6 space-y-3 text-sm leading-6"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckCircleIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MyVacancies;
