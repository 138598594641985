import {
  Avatar,
  Button,
  Dropdown,
  FloatButton,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { CgUnavailable } from "react-icons/cg";
import {
  FaAngellist,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { IoMdWarning } from "react-icons/io";
import {
  MdMailLock,
  MdOutgoingMail,
  MdOutlineGppMaybe,
  MdOutlinePending,
  MdOutlineTextsms,
  MdOutlineVerifiedUser,
} from "react-icons/md";
import { VscWorkspaceUnknown } from "react-icons/vsc";
import { useSelector } from "react-redux";
import {
  COST_PER_ATTEMPT,
  COST_PER_SEARCH,
  COST_PER_UNLOCK_EMAIL,
  CURRENCY_EXCHANGES,
  MAX_BULK_UNLOCK,
} from "../../../data/constants";
import { currencies } from "../../../data/currencies";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
  selectUser,
} from "../../../redux/auth/selectors";
import ApolloService from "../../../service/ApolloService";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import SharedComponent from "./SharedComponent";

const { Option } = Select;

const stringToColour = (str) => {
  if (!str) return undefined;
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

function capitalizeInitials(inputString) {
  if (!inputString) return "";
  return inputString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const humanReadableValue = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(0) + "K";
  } else if (num < 1000000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else {
    return (num / 1000000000).toFixed(0) + "B";
  }
};

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const SearchCandidate = () => {
  const [searchParams, setSearchParams] = useState({
    page: 1,
    per_page: 50,
    person_titles: [],
    q_keywords: "",
    person_locations: [],
    person_seniorities: [],
    contact_email_status: [],
    q_organization_domains: "",
    organization_locations: [],
    organization_num_employees_ranges: [],
  });
  const [data, setData] = useState([]);
  const [phoneCall, setPhoneCall] = useState(null);
  const [searchDuration, setSearchDuration] = useState(null);
  const [smsProspect, setSmsProspect] = useState(null);
  const [emailProspect, setEmailProspect] = useState(null);
  const [importProspect, setImportProspect] = useState(null);
  const [vacancies, setVacancies] = useState([]);
  const [addManualEmail, setAddManualEmail] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    showSizeChanger: false,
  });

  const partner = useSelector(getPartner);
  const user = useSelector(selectUser);
  const darkMode = useSelector(selectDarkMode);
  const loading = useSelector(selectLoading);
  const [modal, contextHolder] = Modal.useModal();

  const debouncedInputChange = debounce(
    (value) => handleInputChange("q_keywords", value),
    50
  );

  const fetchData = async (params) => {
    try {
      const requestStartTime = new Date().getTime();
      const response = await ApolloService.searchPeople(params);
      if (!response.data?.people) return;
      setSelectedRowKeys([]);
      setData(response.data.people);
      setPagination({
        ...pagination,
        current: params.page,
        total: response.data.pagination.total_entries,
      });
      const currentTime = new Date().getTime();
      setSearchDuration(((currentTime - requestStartTime) / 1000).toFixed(2));
    } catch (error) {
      if (
        error?.response?.data?.message === "You need to add a payment method"
      ) {
        const res = await AuthService.userActivation({
          return_url: window.location.href,
        });
        window.location.href = res.data.link;
      }
      console.error("Error fetching data:", error);
    }
  };

  const informationContent =
    partner?.owner === user?._id ? (
      <>
        <div>
          Our candidate sourcing service is billed based on usage. The fees for
          you as our esteemed partner are structured as follows:
        </div>
        <h2 className="mt-5 font-bold text-md">Partner Rates</h2>
        <div className="mt-2">
          Each search execution, including navigation to additional pages within
          a search is charged at{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            COST_PER_SEARCH *
            (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ?? 1)
          ).toFixed(2)}
          .
        </div>
        <div className="mt-2">
          Each attempt to unlock emails will be charged at{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            COST_PER_ATTEMPT *
            (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ?? 1)
          ).toFixed(2)}
          .
        </div>
        <div className="mt-2">
          For each successful unlocking of contact details in a search result,
          the charge is{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            Math.ceil(
              COST_PER_UNLOCK_EMAIL *
                (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ??
                  1) *
                100
            ) / 100
          ).toFixed(2)}
        </div>
        <h2 className="mt-5 font-bold text-md">User Rates</h2>
        <div className="mt-2">
          Charges incurred will be totaled and billed to your primary payment
          method at the end of each billing period (weekly).
        </div>
        <div className="mt-5">
          As a valued partner, you have the opportunity to resell this unique
          system to your users. The fee structure for your users is as follows:
        </div>
        <div className="mt-5">
          For each search execution, including navigation to additional pages
          within a search, your user pays{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            COST_PER_SEARCH *
            ((user?.candidateSourcingProfitFactor ||
              partner?.candidateSourcingProfitFactor) ??
              1.5) *
            (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ?? 1)
          ).toFixed(2)}
          .
        </div>
        <div className="mt-2">
          Each attempt to unlock emails will be charged at{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            COST_PER_ATTEMPT *
            ((user?.candidateSourcingProfitFactor ||
              partner?.candidateSourcingProfitFactor) ??
              1.5) *
            (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ?? 1)
          ).toFixed(2)}
          .
        </div>
        <div className="mt-2">
          For each successful unlocking of contact details in a search result,
          your user pays{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            Math.ceil(
              COST_PER_UNLOCK_EMAIL *
                ((user?.candidateSourcingProfitFactor ||
                  partner?.candidateSourcingProfitFactor) ??
                  1.5) *
                (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ??
                  1) *
                100
            ) / 100
          ).toFixed(2)}
        </div>
        <div className="mt-5">
          Charges to your users will be invoiced directly through your Stripe
          account on a weekly billing cycle. Concurrently, we will bill you for
          your users' usage at the reduced rates according to Partner Rates
          stipulated above (
          {(user?.candidateSourcingProfitFactor ||
            partner?.candidateSourcingProfitFactor) ??
            1.5}{" "}
          times less than the amount you earned from your user).
        </div>
      </>
    ) : (
      <>
        <div>
          Our candidate sourcing service is billed based on usage. The fees are
          structured as follows:
        </div>
        <div className="mt-5">
          Each search execution, including navigation to additional pages within
          a search is charged at{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            COST_PER_SEARCH *
            ((user?.candidateSourcingProfitFactor ||
              partner?.candidateSourcingProfitFactor) ??
              1.5) *
            (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ?? 1)
          ).toFixed(2)}
          .
        </div>
        <div className="mt-2">
          Each attempt to unlock emails will be charged at{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            COST_PER_ATTEMPT *
            ((user?.candidateSourcingProfitFactor ||
              partner?.candidateSourcingProfitFactor) ??
              1.5) *
            (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ?? 1)
          ).toFixed(2)}
          .
        </div>
        <div className="mt-2">
          For each successful unlocking of contact details in a search result ,
          the charge is{" "}
          {currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$"}
          {(
            Math.ceil(
              COST_PER_UNLOCK_EMAIL *
                ((user?.candidateSourcingProfitFactor ||
                  partner?.candidateSourcingProfitFactor) ??
                  1.5) *
                (CURRENCY_EXCHANGES?.[partner?.currency?.toLowerCase?.()] ??
                  1) *
                100
            ) / 100
          ).toFixed(2)}
        </div>
        <div className="mt-5">
          Charges incurred will be totaled and billed to your primary payment
          method at the end of each billing period (weekly).
        </div>
      </>
    );

  const preFetchData = (searchParams) => {
    if (!localStorage.consentCandidateSourcingCost)
      return modal.confirm({
        title: "Confirm Fees",
        content: informationContent,
        okText: "I agree to the payment policy",
        cancelText: "Cancel",
        closable: true,
        onOk: async () => {
          localStorage.consentCandidateSourcingCost = "true";
          fetchData({
            ...searchParams,
            page: 1,
          });
        },
      });

    fetchData({
      ...searchParams,
      page: 1,
    });
  };

  const handleInputChange = (name, value) => {
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTableChange = (pagination) => {
    setSearchParams({
      ...searchParams,
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  };

  const renderLink = (url, Icon) =>
    url ? (
      <a href={url} target="_blank">
        <Icon />
      </a>
    ) : (
      <></>
    );

  const columns = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
      render: (a, record) => (
        <Space>
          <Avatar src={record.photo_url} />
          <div>{a}</div>
          {record?.phone_numbers?.map?.((num) => (
            <>
              {partner?.twilioAccountSID && partner?.twilioTwimlAppSID ? (
                <Space>
                  <a
                    title={`${capitalizeInitials(
                      num?.type?.replace?.(/\_/g, " ")
                    )}: ${num?.raw_number ?? ""}`}
                    onClick={() => {
                      setPhoneCall({
                        ...record,
                        phone: num?.sanitized_number,
                      });
                    }}
                  >
                    <FaPhone />
                  </a>
                  <a
                    title={`${capitalizeInitials(
                      num?.type?.replace?.(/\_/g, " ")
                    )}: ${num?.raw_number ?? ""}`}
                    onClick={() => {
                      setSmsProspect({
                        ...record,
                        phone: num?.sanitized_number,
                      });
                    }}
                  >
                    <MdOutlineTextsms />
                  </a>
                </Space>
              ) : (
                <a
                  title={`${capitalizeInitials(
                    num?.type?.replace?.(/\_/g, " ")
                  )}: ${num?.raw_number ?? ""}`}
                  href={`tel:${num?.sanitized_number}`}
                >
                  <FaPhone />
                </a>
              )}
            </>
          ))}
        </Space>
      ),
    },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      render: (a) => (
        <div
          title={a}
          className="whitespace-nowrap max-w-[150px] text-ellipsis truncate"
        >
          {a}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (a, record) => (
        <div className="flex gap-1">
          {record?.personal_emails?.length > 0 ? (
            record?.personal_emails?.map?.((email, i) => (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: <div>Import into funnel</div>,
                      onClick: () => {
                        setImportProspect({
                          ...record,
                          email,
                          phone: record?.phone_numbers?.[0]?.sanitized_number,
                        });
                        CrudService.search("Vacancy", 20, 1, {}).then(
                          ({ data }) => {
                            setVacancies(data.items);
                          }
                        );
                      },
                    },
                    {
                      key: "2",
                      label: <div>Send email</div>,
                      onClick: () => setEmailProspect({ ...record, email }),
                    },
                    {
                      key: "3",
                      label: <div>Copy email: {email}</div>,
                      onClick: () => {
                        navigator.clipboard.writeText(email);
                        message.success("Copied to clipboard");
                      },
                    },
                  ],
                }}
              >
                <MdOutgoingMail
                  className="cursor-pointer"
                  key={i}
                  color="green"
                />
              </Dropdown>
            ))
          ) : a === "email_not_unlocked@domain.com" &&
            !record?.alreadyRequested ? (
            <Tooltip title="Click to unlock email" className="cursor-pointer">
              <Popconfirm
                title={`Confirm unlocking this email?`}
                onConfirm={async () => {
                  const result = await ApolloService.matchPeople({
                    ...record,
                    reveal_personal_emails: true,
                  });
                  const newResult = result.data.person;

                  const newItem = await CrudService.create(
                    "ApolloPeople",
                    newResult
                  );
                  document.dispatchEvent(
                    new CustomEvent("APOLLO_PEOPLE_UPDATE")
                  );

                  setData((data) => {
                    const current = [...data];
                    const thisPerson = current.find((a) => a.id === record.id);
                    for (const key of Object.keys(newResult)) {
                      thisPerson[key] = newResult[key];
                    }
                    thisPerson.alreadyRequested = true;
                    thisPerson._id = newItem?.data?.result?._id;
                    if (thisPerson.personal_emails?.length === 0)
                      message.info("Unable to verify contact.");

                    return current;
                  });
                }}
              >
                <MdMailLock color="orange" />
              </Popconfirm>
            </Tooltip>
          ) : (
            <Tooltip
              title="Click to add email manually"
              className="cursor-pointer"
              onClick={() => setAddManualEmail(record)}
            >
              <MdOutgoingMail />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Email Status",
      dataIndex: "email_status",
      key: "email_status",
      render: (a) => (
        <>
          {{
            verified: (
              <div title="Verified">
                <MdOutlineVerifiedUser color="green" />
              </div>
            ),
            guessed: (
              <div title="Guessed">
                <MdOutlineGppMaybe />
              </div>
            ),
            unavailable: (
              <div title="Unavailable">
                <CgUnavailable />
              </div>
            ),
            bounced: (
              <div title="Bounced">
                <IoMdWarning color="red" />
              </div>
            ),
            pending_manual_fulfillment: (
              <div title="Pending Manual Fulfillment">
                <MdOutlinePending />
              </div>
            ),
          }?.[a] ?? <VscWorkspaceUnknown />}
        </>
      ),
    },
    {
      title: "Likely to Reply",
      dataIndex: "is_likely_to_engage",
      key: "is_likely_to_engage",
      render: (a) => (
        <>{a ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</>
      ),
    },
    {
      title: "Social Links",
      key: "links",
      render: (text, record) => (
        <Space>
          {renderLink(record?.linkedin_url ?? null, FaLinkedin)}
          {renderLink(record?.twitter_url ?? null, FaTwitter)}
          {renderLink(record?.facebook_url ?? null, FaFacebook)}
          {renderLink(record?.github_url ?? null, FaGithub)}
          {renderLink(record?.angellist_url ?? null, FaAngellist)}
        </Space>
      ),
    },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Country", dataIndex: "country", key: "country" },
    {
      title: "Company",
      dataIndex: "organization",
      key: "organization_name",
      render: (a) => (
        <Space>
          <Avatar src={a?.logo_url} />
          <a href={a?.website_url} target="_blank">
            {a?.name ?? ""}
          </a>
          {renderLink(a?.linkedin_url ?? null, FaLinkedin)}
          {renderLink(a?.twitter_url ?? null, FaTwitter)}
          {renderLink(a?.facebook_url ?? null, FaFacebook)}
          {renderLink(a?.github_url ?? null, FaGithub)}
          {renderLink(a?.angellist_url ?? null, FaAngellist)}
        </Space>
      ),
    },
    {
      title: "Departments",
      dataIndex: "departments",
      key: "departments",
      render: (a) => (
        <Space direction="vertical">
          {a?.map?.((x) => (
            <Tag color={stringToColour(x)}>
              {capitalizeInitials(x?.replace?.(/\_/g, " "))}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Sub Departments",
      dataIndex: "subdepartments",
      key: "subdepartments",
      render: (a) => (
        <Space direction="vertical">
          {a?.map?.((x) => (
            <Tag color={stringToColour(x)}>
              {capitalizeInitials(x?.replace?.(/\_/g, " "))}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Functions",
      dataIndex: "functions",
      key: "functions",
      render: (a) => (
        <Space direction="vertical">
          {a?.map?.((x) => (
            <Tag color={stringToColour(x)}>
              {capitalizeInitials(x?.replace?.(/\_/g, " "))}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      key: "seniority",
      render: (a) => (
        <Space direction="vertical">
          <Tag color={stringToColour(a)}>
            {capitalizeInitials(a?.replace(/\_/g, " "))}
          </Tag>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          className="dark:bg-gray-900 mt-2 rounded-md h-8 border-gray-700"
          placeholder="Enter Keywords"
          onChange={(e) => debouncedInputChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") preFetchData(searchParams);
          }}
        />

        <Select
          className="mt-2"
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Enter Seniority Level"
          value={searchParams?.person_seniorities}
          onChange={(value) => handleInputChange("person_seniorities", value)}
        >
          <Option value={"owner"}>Owner</Option>
          <Option value={"founder"}>Founder</Option>
          <Option value={"c_suite"}>C Suite</Option>
          <Option value={"partner"}>Partner</Option>
          <Option value={"vp"}>Vp</Option>
          <Option value={"head"}>Head</Option>
          <Option value={"director"}>Director</Option>
          <Option value={"manager"}>Manager</Option>
          <Option value={"senior"}>Senior</Option>
          <Option value={"entry"}>Entry</Option>
          <Option value={"intern"}>Intern</Option>
        </Select>

        <Select
          className="mt-2"
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Enter Job Titles"
          onChange={(value) => handleInputChange("person_titles", value)}
        ></Select>

        <Select
          className="mt-2"
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Enter Person Locations (City, State, Country, Region)"
          onChange={(value) => handleInputChange("person_locations", value)}
        ></Select>

        <Select
          className="mt-2"
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Enter Organization Headcount"
          value={searchParams?.organization_num_employees_ranges}
          onChange={(value) =>
            handleInputChange("organization_num_employees_ranges", value)
          }
        >
          {[
            "1-10",
            "11-20",
            "21-50",
            "51-100",
            "101-200",
            "201-500",
            "501-1000",
            "1001-2000",
            "2001-5000",
            "5001-10000",
            "10001+",
          ].map((a) => (
            <Option value={a?.replace("-", ",")}>{a}</Option>
          ))}
        </Select>

        <Select
          className="mt-2"
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Enter Organization Domains"
          value={
            searchParams?.q_organization_domains
              ?.split?.("\n")
              ?.map?.((a) => a?.trim?.())
              ?.filter?.((a) => !!a) ?? []
          }
          onChange={(value) =>
            handleInputChange("q_organization_domains", value.join("\n"))
          }
        ></Select>

        <Select
          className="mt-2"
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Email Status"
          value={searchParams?.contact_email_status}
          onChange={(value) => handleInputChange("contact_email_status", value)}
        >
          <Option value={"verified"}>Verified</Option>
          <Option value={"guessed"}>Guessed</Option>
          <Option value={"unavailable"}>Unavailable</Option>
          <Option value={"bounced"}>Bounced</Option>
          <Option value={"pending_manual_fulfillment"}>
            Pending Manual Fulfillment
          </Option>
        </Select>
      </form>

      <div className="w-full flex justify-between mt-2">
        {pagination?.total ? (
          <div className="flex items-center">
            {humanReadableValue(pagination?.total)} Result
            {pagination?.total > 1 ? "s" : ""}
            {searchDuration && ` in ${searchDuration} seconds`}
          </div>
        ) : (
          <div />
        )}

        <Space>
          <BsInfoCircleFill
            size={22}
            className="text-indigo-500 cursor-pointer"
            onClick={() =>
              modal.confirm({
                title: "Candidate Sourcing Fees",
                content: informationContent,
                okText: "Understood",
                cancelText: "Close",
                closable: true,
              })
            }
          />
          <Button
            className="text-sm bg-indigo-500 text-white rounded"
            loading={loading}
            onClick={() => preFetchData(searchParams)}
          >
            Search
          </Button>
        </Space>
      </div>

      {selectedRowKeys?.length > 0 && (
        <FloatButton
          shape="square"
          type="primary"
          style={{ right: 94, bottom: 10, width: 150, margin: 10 }}
          icon={
            loading ? (
              <Spin>
                <div>Bulk Unlock ({selectedRowKeys.length})</div>
              </Spin>
            ) : (
              <div>Bulk Unlock ({selectedRowKeys.length})</div>
            )
          }
          className="text-sm text-indigo-500 text-white rounded"
          loading={loading}
          onClick={async () => {
            if (loading) return;
            const selectedData = selectedRowKeys
              .map((id) => data.find((d) => d.id === id))
              .filter((a) => !!a)
              .filter(
                (a) =>
                  !a?.alreadyRequested &&
                  (!a?.personal_emails || a?.personal_emails?.length === 0)
              );

            if (selectedData.length > MAX_BULK_UNLOCK)
              return message.info(
                `You can only unlock up to ${MAX_BULK_UNLOCK} prospects at once`
              );

            if (selectedData.length === 0)
              return message.info(
                "None of the selected prospects can be unlocked"
              );

            const result = await ApolloService.bulkMatchPeople({
              details: selectedData,
              reveal_personal_emails: true,
            });
            const newResult = result.data;

            await CrudService.create("ApolloPeople", {
              bulkItems: newResult,
            });
            document.dispatchEvent(new CustomEvent("APOLLO_PEOPLE_UPDATE"));

            const quantity = newResult.filter(
              (a) => a?.personal_emails?.length > 0
            ).length;
            message.info(`Unlocked ${quantity} new contacts`);

            setData((data) => {
              const current = [...data];

              for (const newRes of newResult) {
                const thisPerson = current.find((a) => a.id === newRes.id);
                for (const key of Object.keys(newRes)) {
                  thisPerson[key] = newRes[key];
                }
                thisPerson.alreadyRequested = true;
              }

              return current;
            });
            setSelectedRowKeys([]);
          }}
        />
      )}

      <div className="overflow-auto">
        <Table
          className="mt-2"
          columns={columns}
          dataSource={data}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRows) => {
              setSelectedRowKeys(newSelectedRowKeys);
            },
          }}
          rowKey={"id"}
          pagination={{
            ...pagination,
            onChange: (e) => {
              const newSearchParams = {
                ...searchParams,
                page: e,
                per_page: pagination.pageSize,
              };
              setSearchParams(newSearchParams);
              fetchData(newSearchParams);
            },
          }}
          onChange={handleTableChange}
        />
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!addManualEmail}
        onCancel={() => setAddManualEmail(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        title="Manual Email Entry"
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const email = e.target[0].value;
            const personal_emails = [
              ...(addManualEmail?.personal_emails ?? []),
              email,
            ];

            if (!!addManualEmail?._id)
              await CrudService.update("ApolloPeople", addManualEmail._id, {
                personal_emails,
              });
            setData((data) => {
              const current = [...data];
              const thisPerson = current.find(
                (a) => a.id === addManualEmail.id
              );
              thisPerson.personal_emails = personal_emails;

              return current;
            });
            setAddManualEmail(null);
          }}
        >
          <div className="mb-2 mt-5">
            <input
              type="email"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder="Email"
              defaultValue={importProspect?.email}
            />
          </div>
          <div className="w-full justify-end flex mt-2">
            <Button
              className="text-sm bg-indigo-500 text-white rounded"
              loading={loading}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </Modal>

      <SharedComponent
        importProspect={importProspect}
        setImportProspect={setImportProspect}
        emailProspect={emailProspect}
        setEmailProspect={setEmailProspect}
        setVacancies={setVacancies}
        vacancies={vacancies}
        phoneCall={phoneCall}
        setPhoneCall={setPhoneCall}
        smsProspect={smsProspect}
        setSmsProspect={setSmsProspect}
      />

      {contextHolder}
    </div>
  );
};

export default SearchCandidate;
