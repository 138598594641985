import React from "react";
import ZoomService from "../../../service/ZoomService";

const ConnectZoom = () => {
  return (
    <>
      <h2 className="font-semibold text-lg">
        Connect Your Zoom to Get Started
      </h2>

      <div>
        <p>
          We're excited to enhance your interview process by integrating with
          Zoom! Our Web SDK allows for seamless, real-time interviews directly
          through our platform, eliminating the need for you or your candidates
          to install Zoom. Of course, should you prefer, the option to use the
          Zoom app on your device remains available. To take advantage of this
          streamlined scheduling and interview experience, please authorize our
          platform to connect with your Zoom account. This simple step ensures
          that a meeting is effortlessly created for each candidate interview
          scheduled with you.
        </p>
      </div>

      <div className="mt-5">
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            const res = await ZoomService.getAuthURI();
            window.location.href = res.data.authUri;
          }}
        >
          Click here to connect
        </button>
      </div>
    </>
  );
};

export default ConnectZoom;
