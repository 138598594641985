import { Divider, InputNumber, Space, Tooltip } from "antd";
import React from "react";
import { FaDeleteLeft } from "react-icons/fa6";
import { GrInfo } from "react-icons/gr";
import { Mention, MentionsInput } from "react-mentions";
import { removeAtIndex, replaceAtIndex } from ".";
import classNamesBody from "../Message/body.module.css";
import classNames from "../Message/example.module.css";

const Messaging = ({ softValue, setSoftValue }) => {
  return (
    <>
      <div className="px-4 py-6 sm:p-8">
        <div className="mt-2">
          {softValue?.messagingAutomation?.map?.((messageTemplate, index) => (
            <div key={index}>
              <div className="space-x-2">
                <div>
                  <div className="flex justify-between">
                    <label className="font-bold">Subject</label>
                    <FaDeleteLeft
                      size={25}
                      title="Delete"
                      color="#333"
                      className="cursor-pointer"
                      onClick={() =>
                        setSoftValue((v) => ({
                          ...v,
                          messagingAutomation: removeAtIndex(
                            v.messagingAutomation,
                            index
                          ),
                        }))
                      }
                    />
                  </div>

                  <MentionsInput
                    placeholder="Type # to browse variables"
                    value={messageTemplate.subject}
                    onChange={(_, value) => {
                      setSoftValue((v) => ({
                        ...v,
                        messagingAutomation: replaceAtIndex(
                          v.messagingAutomation,
                          index,
                          {
                            subject: value,
                            body: v.messagingAutomation[index].body,
                            hours: v.messagingAutomation[index].hours,
                          }
                        ),
                      }));
                    }}
                    classNames={classNames}
                    a11ySuggestionsListLabel={"Possible variables"}
                  >
                    <Mention
                      trigger="#"
                      className={"bg-indigo-100"}
                      data={[
                        { id: "myFirstname", display: "My Firstname" },
                        { id: "myLastname", display: "My Lastname" },
                        { id: "myEmail", display: "My Email" },
                        { id: "myPhone", display: "My Phone" },
                        { id: "userFirstname", display: "User Firstname" },
                        { id: "candidateToken", display: "Candidate Token" },
                        { id: "userLastname", display: "User Lastname" },
                        { id: "userEmail", display: "User Email" },
                        { id: "userPhone", display: "User Phone" },
                        { id: "companyName", display: "Company Name" },
                        { id: "companyWebsite", display: "Company Website" },
                        { id: "companyAddress", display: "Company Address" },
                        {
                          id: "userCalendarLink",
                          display: "User Calendar Link",
                        },
                      ]}
                    />
                  </MentionsInput>
                </div>

                <div>
                  <label className="font-bold">Body</label>
                  <MentionsInput
                    placeholder="Type # to browse variables"
                    value={messageTemplate.body}
                    onChange={(_, value) => {
                      setSoftValue((v) => ({
                        ...v,
                        messagingAutomation: replaceAtIndex(
                          v.messagingAutomation,
                          index,
                          {
                            subject: v.messagingAutomation[index].subject,
                            hours: v.messagingAutomation[index].hours,
                            body: value,
                          }
                        ),
                      }));
                    }}
                    classNames={classNamesBody}
                    a11ySuggestionsListLabel={"Possible variables"}
                  >
                    <Mention
                      trigger="#"
                      className={"bg-indigo-100"}
                      data={[
                        { id: "myFirstname", display: "My Firstname" },
                        { id: "myLastname", display: "My Lastname" },
                        { id: "myEmail", display: "My Email" },
                        { id: "myPhone", display: "My Phone" },
                        { id: "userFirstname", display: "User Firstname" },
                        { id: "candidateToken", display: "Candidate Token" },
                        { id: "userLastname", display: "User Lastname" },
                        { id: "userEmail", display: "User Email" },
                        { id: "userPhone", display: "User Phone" },
                        { id: "companyName", display: "Company Name" },
                        { id: "companyWebsite", display: "Company Website" },
                        { id: "companyAddress", display: "Company Address" },
                        {
                          id: "userCalendarLink",
                          display: "User Calendar Link",
                        },
                      ]}
                    />
                  </MentionsInput>
                </div>

                <Space>
                  <label className="font-bold">Hours after Signup</label>
                  <Tooltip
                    title={`This message will be sent ${
                      messageTemplate?.hours ?? 0
                    } after user signs up.`}
                  >
                    <GrInfo />
                  </Tooltip>
                </Space>
                <div>
                  <InputNumber
                    value={messageTemplate.hours}
                    onChange={(value) => {
                      setSoftValue((v) => ({
                        ...v,
                        messagingAutomation: replaceAtIndex(
                          v.messagingAutomation,
                          index,
                          {
                            subject: v.messagingAutomation[index].subject,
                            hours: value,
                            body: v.messagingAutomation[index].body,
                          }
                        ),
                      }));
                    }}
                  />
                </div>

                <Divider />
              </div>
            </div>
          ))}
        </div>

        <button
          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
          onClick={(e) => {
            setSoftValue((v) => ({
              ...v,
              messagingAutomation: [
                ...v.messagingAutomation,
                { subject: "", body: "", hours: 0 },
              ],
            }));
          }}
        >
          + Add Message
        </button>
      </div>
    </>
  );
};

export default Messaging;
