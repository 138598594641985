import { Button, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../hook/useWindowDimensions";
import { selectDarkMode, selectLoading } from "../../../redux/auth/selectors";
import { store } from "../../../redux/store";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";
import PhoneCallBox from "../PhoneCallBox";
import ContactMessage from "./ContactMessage";
import SMSMessage from "./SMSMessage";

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const SharedComponent = ({
  importProspect,
  setImportProspect,
  emailProspect,
  setEmailProspect,
  setVacancies,
  vacancies,
  phoneCall,
  setPhoneCall,
  smsProspect,
  setSmsProspect,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [vacancyStages, setVacancyStages] = useState([]);
  const [lastProcessedVacancyId, setLastProcessedVacancyId] = useState(null);
  const darkMode = useSelector(selectDarkMode);
  const { width } = useWindowDimensions();
  const loading = useSelector(selectLoading);

  useEffect(() => {
    if (!importProspect) {
      setVacancies([]);
      setVacancyStages([]);
      setLastProcessedVacancyId(null);
    }
    if (!importProspect?.VacancyId) return;
    if (lastProcessedVacancyId === importProspect?.VacancyId) return;

    setLastProcessedVacancyId(importProspect?.VacancyId);
    CrudService.search("VacancyStage", 1000, 1, {
      filters: { vacancyId: importProspect?.VacancyId },
      sort: { sort: 1, createdAt: 1 },
    }).then(({ data }) => {
      setVacancyStages(data.items);
    });
  }, [importProspect]);

  const debouncedVacancySearch = debounce((value) => {
    if (value)
      CrudService.search("Vacancy", 20, 1, {
        text: value,
      }).then(({ data }) => {
        setVacancies(data.items);
        setDropdownVisible(false);
        setTimeout(() => setDropdownVisible(true), 100);
      });
  }, 700);

  return (
    <>
      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!importProspect}
        onCancel={() => setImportProspect(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        title="Import Prospect"
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const firstname = e.target[0].value;
            const lastname = e.target[1].value;
            const email = e.target[2].value;

            if (!importProspect?.VacancyId)
              return message.info("Please select a vacancy");
            if (!importProspect?.stageId)
              return message.info("Please select a stage");

            const formData = {
              firstname,
              lastname,
              email,
              phone: importProspect?.phone ?? "",
            };
            const result = await CrudService.create("VacancySubmission", {
              bulkItems: [
                {
                  VacancyId: importProspect?.VacancyId,
                  stageId: importProspect?.stageId,
                  formData,
                  searchIndex: JSON.stringify(formData),
                },
              ],
            });

            if (result.data?.result)
              await ATSService.importCandidates({
                candidateIds: result.data.result.map((a) => a._id),
              });

            setImportProspect(null);
            message.success("Prospect has been successfuly imported");
          }}
        >
          <div className="mb-2 mt-5">
            <input
              type="text"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder="Firstname"
              defaultValue={importProspect?.first_name ?? ""}
            />
            <input
              type="text"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder="Lastname"
              defaultValue={importProspect?.last_name ?? ""}
            />
            <input
              type="email"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder="Email"
              defaultValue={importProspect?.email}
            />

            <Select
              showSearch
              allowClear
              onClear={() => {
                setVacancies([]);
              }}
              options={vacancies.map((vacancy) => ({
                label: (vacancy?.alternativeName || vacancy?.name) ?? "",
                value: vacancy._id,
              }))}
              className="w-full h-11 mt-2 dark:bg-gray-900"
              placeholder="Search Vacancies"
              onSearch={(searchText) => {
                if (searchText) debouncedVacancySearch(searchText);
              }}
              value={importProspect?.VacancyId}
              onChange={(e) => {
                setImportProspect((prospect) => ({
                  ...prospect,
                  VacancyId: e,
                }));
              }}
              open={dropdownVisible && vacancies?.length > 0}
              onDropdownVisibleChange={setDropdownVisible}
            />

            {importProspect?.VacancyId && (
              <Select
                options={vacancyStages.map((stage) => ({
                  label: stage?.name ?? "",
                  value: stage._id,
                }))}
                className="w-full h-11 mt-2 dark:bg-gray-900"
                placeholder="Select Stage"
                value={importProspect?.stageId}
                onChange={(e) => {
                  setImportProspect((prospect) => ({
                    ...prospect,
                    stageId: e,
                  }));
                }}
              />
            )}
          </div>
          <div className="w-full justify-end flex mt-2">
            <Button
              className="text-sm bg-indigo-500 text-white rounded"
              loading={loading}
              htmlType="submit"
            >
              Import Prospect
            </Button>
          </div>
        </form>
      </Modal>

      {width < 500 ? (
        <div>
          <Modal
            wrapClassName={`${darkMode ? "dark" : ""}`}
            open={!!phoneCall}
            onCancel={() => {
              store.dispatch(setPhoneCall(null));
              document.dispatchEvent(new CustomEvent("HANG_UP_PHONE"));
            }}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
            destroyOnClose
          >
            <PhoneCallBox
              customerData={{
                firstname: phoneCall?.first_name ?? "",
                lastname: phoneCall?.last_name ?? "",
                phone: phoneCall?.phone ?? "",
              }}
            />
          </Modal>
        </div>
      ) : (
        <>
          {!!phoneCall && (
            <Draggable>
              <div
                className="bg-white dark:bg-gray-900 w-[320px] fixed rounded-md p-2"
                style={{ zIndex: 2000, bottom: 100, right: 20 }}
              >
                <div
                  className="absolute  cursor-pointer bg-gray-200 rounded-md transition ease-in-out delay-150 hover:scale-110"
                  style={{ zIndex: 2001, top: 5, right: 5 }}
                  onTouchStart={() => {
                    setPhoneCall(null);
                    document.dispatchEvent(new CustomEvent("HANG_UP_PHONE"));
                  }}
                  onClick={() => {
                    setPhoneCall(null);
                    document.dispatchEvent(new CustomEvent("HANG_UP_PHONE"));
                  }}
                >
                  <RxCross2
                    size={25}
                    className="text-black dark:text-gray-600"
                  />
                </div>
                <PhoneCallBox
                  customerData={{
                    firstname: phoneCall?.first_name ?? "",
                    lastname: phoneCall?.last_name ?? "",
                    phone: phoneCall?.phone ?? "",
                  }}
                />{" "}
              </div>
            </Draggable>
          )}
        </>
      )}

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!emailProspect}
        onCancel={() => setEmailProspect(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        title="AI Outreach"
      >
        <ContactMessage
          recipientData={emailProspect}
          onSend={() => {
            setEmailProspect(null);
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!smsProspect}
        onCancel={() => setSmsProspect(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        title="AI Outreach"
      >
        <SMSMessage
          recipientData={smsProspect}
          onSend={() => {
            setSmsProspect(null);
          }}
        />
      </Modal>
    </>
  );
};

export default SharedComponent;
